import React, {useState, useLayoutEffect} from 'react'

export const useMobile = () => {
    const [isMobile, setIsMobile] = useState(true)
      

    useLayoutEffect(()=>{
     const handleResize = () => setIsMobile(window.innerWidth < 768);

     handleResize()

     window.addEventListener("resize", handleResize);

     return () => window.removeEventListener("resize", handleResize);

    }, [])

   return isMobile
}