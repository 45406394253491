import React from 'react';

export const CursorArrowIcon = (props: Record<string, unknown>) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_1636_4764)">
      <path
        d="M20.1369 15.0001L14.4226 9.28578L17.2797 6.42864C17.4595 6.24656 17.5871 6.01952 17.6492 5.77126C17.7112 5.523 17.7054 5.26264 17.6324 5.01737C17.5594 4.77211 17.4219 4.55096 17.2342 4.37703C17.0465 4.2031 16.8155 4.08278 16.5654 4.02864L3.33686 0.757212C3.09817 0.698471 2.84837 0.70231 2.61161 0.768358C2.37484 0.834406 2.15912 0.960429 1.98531 1.13424C1.8115 1.30805 1.68548 1.52377 1.61943 1.76053C1.55338 1.9973 1.54954 2.2471 1.60829 2.48578L4.87971 15.7144C4.94148 15.9599 5.06738 16.1845 5.24452 16.3654C5.42166 16.5463 5.64367 16.6768 5.88785 16.7437C6.13202 16.8105 6.38957 16.8113 6.63413 16.7459C6.8787 16.6805 7.1015 16.5513 7.27971 16.3715L10.1369 13.5144L15.8511 19.2286L20.1369 15.0001Z"
        stroke="#8181A5"
        stroke-width="1.71429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1636_4764">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.851074)"
        />
      </clipPath>
    </defs>
  </svg>
);
