import React, { useState } from 'react';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { Row, Col, Button } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router-dom';
import internationalRelationsLogo from '../assets/icons/logo_old.png';

import '../styles/login.scss';
import { toast } from 'react-hot-toast';
import { DesktopLandingContainer } from '../components/DesktopLandingContainer';
import { MobileLandingContainer } from '../components/MobileLandingContainer';
import { InputField } from '../components/InputField';
import { useMobile } from '../_common/hooks/useMobile';

export default function Home() {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi();
  const isMobile = useMobile();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    let params: any = {
      Email: data.email,
    };
    showLoader();
    userApi.forgotPassword(
      params,
      (message: string, resp: any) => {
        console.log({ resp });
        toast.success(resp.Message);
        navigate(`/login`);
        hideLoader();
      },
      (message: string) => {
        toast.error(message);
        hideLoader();
      }
    );
  };

  return isMobile ? (
    <MobileLandingContainer>
      <>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col sm={12}>
                <InputField
                  isAuthMobile
                  id="email"
                  type="email"
                  label="Email"
                  register={register}
                  errors={errors}
                />
              </Col>
            </Row>

            <div className="mobbottom_fixbtn">
              <Button variant="success" className="w-100 mt-2" type="submit">
                Forgot
              </Button>
            </div>
          </form>
        </div>
      </>
    </MobileLandingContainer>
  ) : (
    <DesktopLandingContainer>
      <div className={css({ width: '25em' })}>
        <div className="d-flex justify-content-center gap-3">
          <div className="logo">
            <img src={internationalRelationsLogo} alt="Department of InternationalRelations Logo" />
          </div>
        </div>
        <div className="w-100">
          <div className="text-center">
            <h2 className="d-inline-block mt-5 ">Welcome to DIRCO</h2>
            <h3>Enter your details to proceed further</h3>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset className={'input' + (errors?.email ? ' invalid' : '')}>
              <legend>Email</legend>
              <input
                type="text"
                placeholder="Email"
                {...register('email', {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              ></input>
            </fieldset>
            {errors?.email ? (
              <div className="invalid text-danger">
                {errors.email.type === 'required' ? 'Required' : null}
                {errors.email.type === 'pattern'
                  ? 'Invalid email address'
                  : null}
              </div>
            ) : null}
            <div className="mobbottom_fixbtn">
              <Button variant="success" className="w-100 mt-2" type="submit">
                Forgot
              </Button>
            </div>
          </form>
        </div>
      </div>
    </DesktopLandingContainer>
  );
}
