import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/header";
import CreateAdvisoryAlert from "../components/modals/createAdvisoryAlert.modal";
import "../styles/advisory.scss";
import { BsFiles, BsPlus, BsWhatsapp } from "react-icons/bs";
import { FaShareSquare } from "react-icons/fa";
import LeftPanel from "../components/leftPanel";
import { useAdvisoryApi } from "../_common/hooks/api/advisoryApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { IMAGE_ASSETS } from "../assets";

export default function Advisory() {

    const advisoryApi = useAdvisoryApi()
    const { showLoader, hideLoader } = useAppLoader()
    const user: any = useUserSelector()

    const [createAlert, setCreateAlert] = useState<boolean>(false);
    const [alertList, setAlertList] = useState<any>([]);
    const [attachements, setAttachements] = useState<any>([]);

    const closeCreateAlert = () => { setCreateAlert(false) }

    const getAlerts = () => {
        showLoader()
        advisoryApi.getContactAlert({ contact: user?.contactid },
            (message: string, resp: any) => {
                console.log(resp)
                setAlertList(resp.Alerts)
                setAttachements(resp.Attachments)
                hideLoader()
            },
            (message: string) => {
                console.log(message)
                hideLoader()
            })
    }

    useEffect(() => {
        if (user) {
            getAlerts()
        }
    }, [user])

    return (
        <React.Fragment>
            <CreateAdvisoryAlert
                show={createAlert}
                close={closeCreateAlert}
                getAlerts={getAlerts}
            />
            <Header active={"advisory"}/>
            <Container fluid>
                <div className="wrapper-left-right-panel">
                    <LeftPanel img={IMAGE_ASSETS.travelSuitcases} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."} />
                    <div className="advisory-card">
                        <div className="subcribe-alert" onClick={() => setCreateAlert(true)}>
                            <h3>Subscribe to an alert</h3>
                            <span className="plus"><BsPlus /></span>
                        </div>
                        <h2>Advisory Alerts</h2>
                        {/* <div className="advisory-alert-card">
                            <div className="advisory-alert-card-pic">
                                <img src="../assets/img/advisory-alert-pic.jpg" alt="" />
                            </div>
                            <div className="advisory-alert-card-content">
                                <div className="d-flex justify-content-between mb-3">
                                    <h5>News, Hiking</h5>
                                    <span><BsWhatsapp /></span>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <h4>Hiker gets lost on remote mountain trail in Phuket</h4>
                                    <span><FaShareSquare /></span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                    <span><BsFiles /></span>
                                </div>
                            </div>
                        </div> */}

                        <Row className="advisory-alert-bottom-cards mt-3">

                            {alertList.length > 0 ?
                                alertList.map((alert: any, index: number) => (
                                    <Col md={6} key={index}>
                                        <div className="advisory-alert-bottom-card">
                                            {attachements.length > 0 ?
                                                <div className="advisory-alert-bottom-card-pic">
                                                    <img src="../assets/img/advisory-card-pic.jpg" alt="" />
                                                </div>
                                                : null
                                            }
                                            <div className="advisory-alert-bottom-card-content">
                                                <h5>{alert["xql_type@OData.Community.Display.V1.FormattedValue"]}</h5>
                                                <h4>{alert?.xql_subject}</h4>
                                                <p>{alert["xql_advisory@OData.Community.Display.V1.FormattedValue"]}</p>
                                                <p>{alert["_xql_country_value@OData.Community.Display.V1.FormattedValue"]},{alert["_xql_cityid_value@OData.Community.Display.V1.FormattedValue"]}</p>
                                            </div>
                                        </div>

                                    </Col>
                                ))
                                : null
                            }
                        </Row>
                    </div>

                </div>
            </Container>
        </React.Fragment>
    )
}