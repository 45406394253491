import { css } from '@emotion/css';
import { ReactNode } from 'react';
import travelSuitecase from '../assets/privacy-policy/suitcases.png';

export const MobileLandingContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <div
      className={css({
        width: '100vw',
        height: '100vh',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        overflowX: 'hidden',
        backgroundImage: `url(${travelSuitecase})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        justifyItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
      })}
    >
      <div
        className={css({
          textAlign: 'center',
        })}
      >
        <img
          className={css({
            display: 'inline-block',
            width: 100,
          })}
          src="/assets/img/logo.svg"
          alt="Dirco"
        />
      </div>

      <h1
        className={css({
          color: 'white',
          textAlign: 'center',
        })}
      >
        Travel Smart
      </h1>

      <div
        className={css({
          color: 'white',
          textAlign: 'center',
          marginBottom: 30
        })}
      >
        brought to you by DIRCO
      </div>
      {children}
    </div>
  );
};
