import { css } from '@emotion/css';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import LeftPanel from './leftPanel';
import travelSuitecase from '../assets/privacy-policy/suitcases.png';

export const DesktopLandingContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className={css({ height: '100vh', overflowY: 'hidden' })}>
      <div className="navbar-header">
        <Link to={'/'} className="logo">
          <img src="/assets/img/logo.svg" alt="Dirco" />
          <p>
            International Relations &<br />
            Cooperation
          </p>
        </Link>
      </div>
      <div className="d-flex justify-content-center">
        <LeftPanel
          img={travelSuitecase}
          text={
            'If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly.'
          }
        />
        <div className="card-outer w-75">{children}</div>
      </div>
    </div>
  );
};
