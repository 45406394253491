import React, { useState } from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { InputField } from '../components/InputField'
import '../styles/login.scss';
import { toast } from "react-hot-toast";
import { css } from '@emotion/css';
import internationalRelationsLogo from '../assets/icons/logo_old.png';
import { useMobile } from "../_common/hooks/useMobile";
import { MobileLandingContainer } from "../components/MobileLandingContainer";
import { DesktopLandingContainer } from "../components/DesktopLandingContainer";
import { PasswordToggle } from "../components/PasswordToggle";

export default function Home() {
	const isMobile = useMobile()
	const navigate = useNavigate();
	const { showLoader, hideLoader } = useAppLoader();
	const userApi = useUserApi();
	const [loginError, setLoginError] = useState<string | null>(null);
	const { register, handleSubmit, formState: { errors } } = useForm();

	const onSubmit = (data: any) => {
		let params: any = {
			"Email": data.email,
			"Password": data.password
		}
		showLoader();
		userApi.login(params, (message: string, resp: any) => {
			hideLoader();
			// sessionStorage.setItem('userDetails', JSON.stringify(resp.data.outputParameters.RequestResults))
		}, (message: string) => { // login error..
			toast.error(message)
			setLoginError(message);
			hideLoader();
		});
	}

	return isMobile ?
		(<MobileLandingContainer>
			{/* <div className={css({ flex: 1 })} /> */}
			<>
				<div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Col sm={12}>
								<InputField
									isAuthMobile
									id="email"
									type="email"
									label="Email"
									register={register}
									errors={errors}
								/>
							</Col>
						</Row>
						<Row>
							<PasswordToggle isAuthMobile register={register} errors={errors} label={'Password'} id={'password'} />
						</Row>

						<div className="mobbottom_fixbtn">
							<Button variant="success" className="w-100 mt-2" type="submit">
								Login
							</Button>
						</div>

						<div className={css({ display: 'flex', justifyContent: 'space-between', color: 'white', marginTop: 20 })}>
							<div>
								<input className="form-check-input" type="checkbox" value="" id="flexCheckCheckedDisabled" {...register("remember", {})} />
								<label className="form-check-label" htmlFor="flexCheckCheckedDisabled">
									Remember me
								</label>
							</div>


							<Link to="/forgot-password" className={css({ color: 'white' })}>Forgot Password?</Link>
						</div>
					</form>
				</div>
			</>
		</MobileLandingContainer>) :
		(
			<DesktopLandingContainer>
				<div className={css({ width: '25em' })}>
					<div className="d-flex justify-content-center gap-3">
						<div className="logo">
							<img src={internationalRelationsLogo} />
						</div>
					</div>
					<div className="w-100">
						<div className="text-center">
							<h2 className="d-inline-block mt-5 ">Welcome to DIRCO</h2>
							<h3>Enter your details to proceed further</h3>
						</div>

						<form onSubmit={handleSubmit(onSubmit)}>

							<fieldset className={"input" + (errors?.email ? ' invalid' : '')}>
								<legend>Email</legend>
								<input type="text" placeholder="Email" {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}></input>
							</fieldset>
							{errors?.email ? <div className="invalid text-danger">
								{errors.email.type === "required" ? 'Required' : null}
								{errors.email.type === "pattern" ? 'Invalid email address' : null}
							</div> : null}
							<PasswordToggle register={register} errors={errors} label={'Password'} id={'password'} />
							<Row className="mb-2">
								<Col md={12}>
									<div className="form-check">
										<input className="form-check-input" type="checkbox" value="" id="flexCheckCheckedDisabled" {...register("remember", {})} />
										<label className="form-check-label" htmlFor="flexCheckCheckedDisabled">
											Remember me
										</label>
									</div>
								</Col>
								<Col md={12} className="small">
									<Link to="/forgot-password">Forgot Password?</Link> | <Link to="/welcome">New User?</Link>
								</Col>
							</Row>
							<div className="mobbottom_fixbtn">
								<Button variant="success" className="w-100 mt-2" type="submit">Login</Button>
							</div>
						</form>
					</div>
				</div>
			</DesktopLandingContainer>
		)
}