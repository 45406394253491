import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import NoTravelData from "../components/travel/noTravelData";
import TravelList from "../components/travel/travelList";
import Header from "../components/header";
import { useTravelsApi } from "../_common/hooks/api/travelsApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import LeftPanel from "../components/leftPanel";
import { IMAGE_ASSETS } from "../assets";


export default function Travels() {

    const travelsApi = useTravelsApi()
    const { showLoader, hideLoader } = useAppLoader()
    const user: any = useUserSelector()

    const [travels, setTravels] = useState<any>(null)
    const [upcomingTravels, setUpcomingTravels] = useState<any>([])
    const [pastTravels, setPastTravels] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getTravels = () => {
        showLoader()
        setLoading(true)
        travelsApi.getTravels({
            contact: user.contactid
        },
            (message: string, resp: any) => {
                if (Array.isArray(resp)) {
                    setTravels(resp)
                }
                else {
                    setTravels([])
                }
                hideLoader()
                setLoading(false)
            }, (message: string) => {
                hideLoader()
                setLoading(false)
                console.log(message);
            })
    }

    useEffect(() => {
        if (user) {
            getTravels()
        }
    }, [user])

    useEffect(() => {
        if (travels && travels.length > 0) {
            setUpcomingTravels(travels.filter((item: any) => (Date.parse(item.xql_departuredate) > Date.now())))
            setPastTravels(travels.filter((item: any) => (Date.parse(item.xql_departuredate) < Date.now())))
            // console.log({upcoming})
        }
    }, [travels])

    return (
        <React.Fragment>
            <Header />
            {loading ?
                null
                :
                <Container fluid>
                    <div className="wrapper-left-right-panel">
                        <LeftPanel img={IMAGE_ASSETS.travelSuitcases} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."} />
                        {
                            travels == null ?
                                <NoTravelData />
                                :
                                <TravelList data={travels} upcomingTravels={upcomingTravels} pastTravels={pastTravels} />
                        }
                    </div>
                </Container>
            }
        </React.Fragment>
    )
}