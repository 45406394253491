import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header";
import CasesDetails from "../components/cases/caseDetails";
import { useParams } from "react-router-dom";
import { useCasesApi } from "../_common/hooks/api/casesApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import LeftPanel from "../components/leftPanel";
import { IMAGE_ASSETS } from "../assets";

export default function CaseDetails() {
    const { id } = useParams()
    const casesApi = useCasesApi()
    const { showLoader, hideLoader } = useAppLoader()

    const [caseId, setCaseId] = useState<string>("")
    const [details, setDetails] = useState<any>(null)

    const getCasesDeatails = () => {
        showLoader()
        casesApi.getCasesDetails({ case: caseId },
            (message: string, resp: any) => {
                console.log({ resp })
                setDetails(resp)
                hideLoader()
            }, (message: any) => {
                console.log({ message })
                hideLoader()
            })
    }

    useEffect(() => {
        if (caseId != "") {
            getCasesDeatails()
        }
    }, [caseId])

    useEffect(() => {
        if (id) {
            setCaseId(id)
        }
    }, [id])

    return (
        <React.Fragment>
            <Header active={"cases"} />
            <Container fluid>
                <div className="wrapper-left-right-panel">
                    <LeftPanel img={IMAGE_ASSETS.travelSuitcases} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."} />
                    <CasesDetails data={details} />
                </div>
            </Container>
        </React.Fragment>
    )
}