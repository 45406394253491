import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { User } from '../../../_common/interfaces/models/user';
import { ACTIONS } from '../../../_config'

export interface UserReducer {
  user: User | null;
  token: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  isExpired: any;
  isAuthenticated: boolean;
  passports: [string] | []
}

const initialState: UserReducer = {
  user: null,
  isExpired: false,
  token: null,
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  passports: []
};

const userReducer = (state = initialState, action: ActionExtended) => {
  console.log('ACTION::::::', {action})
  switch (action.type) {
  
    case ACTIONS.USER.LOGIN:
      return {
        ...state,
        user: action.payload.user.response,
        token: action.payload?.token,
        accessToken: action.payload.user.access_token,
        refreshToken: action.payload.user.refresh_token,
        isAuthenticated: true,
        isExpired: action.payload.response?.isExpired,
      };
      case ACTIONS.USER.UPDATE_TOKEN:
        console.log('::::::UPDATE_TOKEN:::::::')
        return {
          ...state,
          accessToken: action.payload.accessToken.access_token,
        };
    case ACTIONS.USER.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        accessToken: null,
        refreshToken: null,
        isAuthenticated: false,
        isExpired: false,
      };
    case ACTIONS.USER.ME:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
