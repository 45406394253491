import React from "react";
import { Dropdown, Nav } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { URLS } from "../_config";
import { GrHomeRounded } from 'react-icons/gr';
import { BsChevronLeft, BsPerson } from 'react-icons/bs'
import '../styles/components/header.scss';
import { ActivityIcon } from "../assets/icons/ActivityIcon";
import { CursorArrowIcon } from "../assets/icons/CursorArrowIcon";
import { TravelIcon } from "../assets/icons/TravelIcon";



type BackMode = {
	pageName?: string;
	backUrl: string;
}

interface incomingProps {
	backMode?: BackMode,
	active?: any,
}


export default function Header({ backMode, active}: incomingProps) {
	const userApi = useUserApi();
	const user: any = useUserSelector()
	const navigate = useNavigate();


	const logout = (e: any) => {
		console.log("logout")
		e.preventDefault();
		e.stopPropagation();
		userApi.logout({}, (message: string, resp: any) => {
			navigate('/')
		}, (message: string) => {

		})
	}

	const updateProfile = () => {
		navigate(URLS.PROFILE_SETTINGS)
	}

	return (

		<React.Fragment>
			{
				backMode ?
					<div className="navbar-header back-mode">
						<Link to={backMode.backUrl} className="icon back"><BsChevronLeft /></Link>
						<p className="label">{backMode.pageName || ''}</p>
						{user ?
							<Dropdown>
								<Dropdown.Toggle className="btn-toggle btn-toggle-svg" id="dropdown-basic">

									<BsPerson />
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item onClick={updateProfile}>View Profile</Dropdown.Item>
									<Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							:
							<Link to="/welcome" className="icon"><BsPerson /></Link>
						}
					</div>
					:
					<div className="navbar-header">
						<Link to={'/'} className="logo">
							<img src="/assets/img/logo.svg" alt="Dirco" />
							<p>International Relations &<br />Cooperation</p>
						</Link>
						<div className="nav-links">
							<Link to="/" className={active == "home" ? "nav-link active" : "nav-link"}>
								<GrHomeRounded />
								<p>Home</p>
							</Link>
						
							<NavLink to={'/travels'}    className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'} >
								<TravelIcon />&nbsp;
								Travel
							</NavLink>

							{/* TODO: check where to link to  */}
							<NavLink to={URLS.CREATE_CASE}    className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'} >
							<ActivityIcon />&nbsp;
							Do you need help?
							</NavLink>

 
							<Nav.Link href={'https://www.dirco.gov.za/websites-of-sa-mission/'}   
							  className='nav-link' target="_blank" rel="noreferer noopener"
							 >
								 <CursorArrowIcon />&nbsp;
							SA Missions
							</Nav.Link>


							{user ?
								<Dropdown>
									<Dropdown.Toggle className={"btn-toggle"} id="dropdown-basic">
										<BsPerson />
										<p>Profile</p>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item onClick={updateProfile}>View Profile</Dropdown.Item>
										<Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>

								:
								<Link to="/welcome" className={"icon"}><BsPerson /></Link>
							}
						</div>
					</div>
			}


		</React.Fragment>

	);
}
