
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';
import { INPUT_MAX_LENGTHS } from '../../_config';
import { css } from '@emotion/css';

interface InputFieldProps {
  label: string;
  id: string;
  errors: FieldErrorsImpl<{
    [x: string]: any;
  }>;
  required?: boolean;
  register: UseFormRegister<FieldValues>;
  registerParams?: Partial<UseFormRegister<FieldValues>>;
  placeholder?: string;
  type?: 'text' | 'email' | 'tel' | 'number' | 'date' | 'password';
  disabled?: boolean;
  step?: number | string;
  prefix?: string;
  outlined?: boolean;
  readOnly?: boolean;
  isAuthMobile?: boolean;
}

export const InputField = ({
  errors,
  required = true,
  id,
  register,
  label,
  registerParams,
  placeholder,
  type = 'text',
  disabled,
  step,
  outlined,
  prefix,
  readOnly,
  isAuthMobile
}: InputFieldProps) => {
  const input = (
    <input
      disabled={disabled}
      readOnly={readOnly}
      type={type}
      className={isAuthMobile ? 'auth-mobile' : ''}
      id={`formControlInput_${id}`}
      placeholder={placeholder}
      {...register(id, {
        required,
        maxLength: INPUT_MAX_LENGTHS.default,
        ...registerParams,
      })}
      step={step}
    />
  );

  return (
    <>
      <fieldset className={`input ${errors[id] ? 'invalid' : ''} ${outlined ? ' filled ' : ''}`}>
        <legend className={isAuthMobile ? 'auth-mobile-legend' : ''}>{label}</legend>
        {input}{' '}
      </fieldset>
      <div className="invalid text-danger">
        <sup>{errors?.[id]?.type === 'required'
          ? `${label} is required`
          : (errors?.[id]?.message as string)}
          {errors?.[id]?.type === 'isFuture' && `Enter a valid ${label}, the expiry date cannot be in the past`}
          {errors?.[id]?.type === 'isBefore' && `${label} must be after Depature Date`}
        </sup>
      </div>
    </>
  );
};
