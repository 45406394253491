import React, { useEffect, useMemo, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Select from '../blocks/Select';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useTravelsApi } from '../../_common/hooks/api/travelsApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import StepIndicators from './stepIndicators';
import ToggleSwitch from '../blocks/toggleSwitch';
import { InputField } from '../InputField';
import { DATE_MM_DD_YYYY_PATTERN } from '../../_common/constants';
import { UTILS } from '../../_common/utils';
import { css } from '@emotion/css';
import { isFuture } from 'date-fns'
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import moment from 'moment';
interface incomingProps {
    onSubmitAddressForm?: any;
    tab?: any
}

export default function Step1({ onSubmitAddressForm, tab }: incomingProps) {

    const { register, handleSubmit, setValue, watch, formState: { errors }, control, reset } = useForm()

    const commonApi = useCommonApi()
    const travelApi = useTravelsApi()
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useAppLoader()
    const userApi = useUserApi()
    const user: any = useUserSelector()

    const [travelReason, setTravelReason] = useState<any>(null)
    const [countries, setCountries] = useState<any>(null)
    const [travelId, setTravelId] = useState<any>(null)
    const [hasSouthAfricanPassport, setHasSouthAfricanPassport] = useState<boolean>(true)
    const [hasTravelInsuarance, setHasTravelInsurance] = useState<boolean>(true)
    // const [defaultPassport, setDefaultPassport] = useState('')
    // const [expirydate, setExpiryDate] = useState('')
    let TravelId: any = sessionStorage.getItem('TravelId')


    const passportWatch = watch('Passport');

    const southAfricaCountryId = useMemo(() => {
        let countryId = countries?.find((country: any) => country.label === 'South Africa')?.value;

        if (hasSouthAfricanPassport) {
            countryId = countries?.find((country: any) => country.label === 'South Africa')?.value;
        }

        if (countryId) {
            setValue("CountryId", countryId)
        }
        return countryId
    }, [countries, hasSouthAfricanPassport])

    const retrieveTravelReason = () => {
        commonApi.retrieveTravelReason({ "DropdownName": "xql_travelreason" },
            (message: string, resp: any) => {
                let options: any = [];
                if (resp.Options && resp.Options.length > 0) {
                    resp.Options.map((item: any) => {
                        item.value = item.Value
                        item.label = item.Label.UserLocalizedLabel.Label
                        options.push(item)
                    })
                    setTravelReason(options)
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const getDefaultPassport = () => {
        userApi.getCitizenPassport({ contact: user.contactid },
            (message: string, resp: any) => {
                if (resp.IsSuccess) {
                    const defaultPassport = resp.Passports.filter((data: any) => data.IsDefault === true
                    )

                    if (defaultPassport.length === 1) {
                        setValue('Passport', defaultPassport[0].Passport)
                        setValue('ExpiryDate', moment(defaultPassport[0].ExpiryDate).format('YYYY-MM-DD'))
                    }
                }

            }, (message: string) => {
                console.log(message);
            }
        )
    }


    const getCountries = () => {
        commonApi.getCountries({},
            (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCountries(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_countryid;
                        return data;
                    }))
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const onSubmit = (data: any) => {
        showLoader()
        let params = {
            "EntityName": "TravelReason",
            "TravelId": travelId,
            "TravelReason": data.TravelReason.value,
            "TravelInsurance": hasTravelInsuarance,
            "TravelWithSAPassport": hasSouthAfricanPassport,
            "CountryId": data.CountryId,
            "Passport": data.Passport,
            "ExpiryDate": data.ExpiryDate
        }

        showLoader();
        travelApi.createTravelStepFour(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message)
            navigate('/travels')
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    useEffect(() => {
        retrieveTravelReason()
        getDefaultPassport()
    }, [])

    useEffect(() => {
        getCountries()
    }, [])

    useEffect(() => {
        if (TravelId == 'undefined') {
            setTravelId(null)
        } else {
            setTravelId(JSON.parse(TravelId))
        }
    }, [TravelId]);

    return (
        <React.Fragment>
            <h1>Register new travel</h1>
            <StepIndicators step={3} />
            <div className="d-flex justify-content-between mt-5">
                <h2>What is the primary reason for your travel?</h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-100" autoComplete='off'>
                <div className="container-fluid p-0">
                    <Row>
                        <Col sm={6}>
                            <fieldset className={"input" + (errors?.TravelReason ? ' invalid' : '')}>
                                <Controller
                                    name={"TravelReason"}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => {
                                        return (<Select
                                            className={"select-box"}
                                            options={travelReason ? travelReason : []}
                                            placeholder={""}
                                            onChange={onChange}
                                            value={value}
                                            defaultValue={value}
                                        />)
                                    }}
                                />
                            </fieldset>
                        </Col>


                        <Col className="d-flex justify-content-between">

                            <div>I have travel insuarance</div>
                            <div>
                                <ToggleSwitch condition={hasTravelInsuarance} setCondition={setHasTravelInsurance} />
                            </div>
                        </Col>

                    </Row>
                </div>

                <div className="d-flex justify-content-between">
                    <p>Are you travelling with a South African passport?</p>
                    <div>
                        <ToggleSwitch condition={hasSouthAfricanPassport} setCondition={setHasSouthAfricanPassport} />
                    </div>
                </div>

                <div className="container-fluid p-0">
                    <Row>

                        <Col sm={6}>

                            <fieldset className={"input" + (errors?.CountryId ? ' invalid' : '')}>
                                <legend>Country where your passport was issued?</legend>
                                <Controller
                                    name={"CountryId"}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => {
                                        return (<Select
                                            className={"select-box"}
                                            options={countries ? countries : []}
                                            placeholder={""}
                                            onChange={onChange}
                                            isDisabled={hasSouthAfricanPassport}
                                            value={value}
                                            defaultValue={value}
                                        />)
                                    }}
                                />
                            </fieldset>

                        </Col>
                        <Col sm={6}>
                            <InputField id='Passport' label='Passport' readOnly={hasSouthAfricanPassport} required={true} register={register} errors={errors} />
                        </Col>
                        <Col sm={6}>
                            <InputField
                                id='ExpiryDate'
                                readOnly={!passportWatch || hasSouthAfricanPassport}
                                type="date" placeholder="YYYY-MM-DD"
                                label='Expiry Date' required={true}
                                register={register}
                                errors={errors}
                                registerParams={{
                                    maxLength: UTILS.makeMaxLengthError(10),
                                    validate: { isFuture: (value: string) => (isFuture(new Date(value))) },
                                    pattern: {
                                        value: DATE_MM_DD_YYYY_PATTERN,
                                        message: 'Date must be in YYYY-MM-DD format',
                                    },
                                }} />
                        </Col>
                    </Row>
                </div>

                <div className="mt-4 d-flex gap-2 login-btns">
                    <Button className="btn" variant="success" type='submit'>Save & continue</Button>
                </div>
            </form>
        </React.Fragment>
    )
}