import React, { useState } from 'react';
import { BsFillEyeSlashFill, BsFillEyeFill } from "react-icons/bs";
import { InputField } from './InputField';
import { Col } from 'react-bootstrap';
import { css } from '@emotion/css';
import { useMobile } from '../_common/hooks/useMobile';

interface incomingProps {
    register: any;
    errors: any;
    id: string;
    label: string;
    isAuthMobile?: boolean;
    sm?: boolean;
}

export const PasswordToggle = ({ register, errors, label, id, isAuthMobile, sm }: incomingProps) => {
    const isMobile = useMobile()
    const [showPassword, setShowPassword] = useState(false)

    return (
        <Col sm={sm? 6: 12}>
            <div className={css({ position: 'relative' })}>
                <InputField
                    isAuthMobile={isAuthMobile}
                    id={id}
                    type={showPassword ? 'text' : 'password'}
                    label={label}
                    register={register}
                    errors={errors}
                />
                {
                    isMobile ?
                        (
                            <div
                                className={css({ position: 'absolute', left: '90%', top: 8, zIndex: 1000, color: 'white', padding: 4, })}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {
                                    showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />
                                }
                            </div>
                        ) :
                        (
                            <div
                                className={css({ position: 'absolute', left: '90%', top: 12, zIndex: 1000, padding: 4, })}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {
                                    showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />
                                }
                            </div>
                        )
                }
            </div>
        </Col>
    )
}