import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import StepIndicators from './stepIndicators';
import * as CountryList from 'country-list-with-dial-code-and-flag';
import { handleKeyPressNumber } from '../../_common/functions';
import Select from '../blocks/Select';
import ToggleSwitch from '../blocks/toggleSwitch';
import { SelectOption } from '../../_common/types';
import { InputField } from '../InputField';
import { DATE_MM_DD_YYYY_PATTERN } from '../../_common/constants';
import { isFuture } from 'date-fns';
import { ApiSearchBox } from '../ApiSearchbox';
import { API_URL } from '../../_config';
interface incomingProps {
    onSubmitAddressForm?: any;
    tab?: any
}

export default function Step2({ onSubmitAddressForm, tab }: incomingProps) {

    const { register, handleSubmit, formState: { errors }, watch, control } = useForm()
    const country = watch('CountryId');
    let ProfileId: any = sessionStorage.getItem("ProfileId")

    const userApi = useUserApi()
    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader()
    const navigate = useNavigate()

    const passportWatch = watch('Passport')
    const expDateWatch = watch('ExpiryDate')

    const [toggle, setToggle] = useState<boolean>(false)
    const [countries, setCountries] = useState<SelectOption[]>([])
    const [cities, setCities] = useState<SelectOption[]>([])
    const [dialCode, setDialCode] = useState<SelectOption[]>([])
    const [profileId, setProfileId] = useState<any>(null)

    const goBack = () => {
        navigate(-1);
    }

    const onSubmit = (data: any) => {
        let params = {
            "EntityName": "Citizen",
            "ContactId": profileId,
            "CountryId": data.CountryId,
            "CityId": data.CityId,
            "PermanentResidenceAbroad": toggle,
            "Address": data.Address,
            "DialCode": data.DialCode,
            "Telephone": data.Telephone,
            "Passport": data.Passport,
            "ExpiryDate": data.ExpiryDate,
        }

        showLoader();
        userApi.registerStepTwo(params, (message: string, resp: any) => {
            console.log({ resp })
            hideLoader();
            toast.success(message)
            navigate('/signup?step=3')
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    const getCountries = () => {
        commonApi.getCountries({},
            (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCountries(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_countryid;
                        return data;
                    }))
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const getCities = (id: any = country?.value) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.getCities({
                CountryId: id
            }, (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCities(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_cityid;
                        return data;
                    }))
                }
                setTimeout(() => {
                    resolve()
                }, 300)
            }, (message: string) => {
                // console.log(message);
                reject()
            });
        })
    }

    const getDialCode = (data: any) => {
        if (data.length > 0) {
            setDialCode(data.map((item: any) => {
                item.label = `(${item.dial_code}) ${item.name}`;
                item.value = item.dial_code;
                return item;
            }).sort((a: SelectOption, b: SelectOption) => Number(a.value) - Number(b.value)))
        }
    }

    const dialCodeOptionUI = (props: any) => {
        const { innerProps, innerRef, data } = props;
        return (
            <div ref={innerRef} {...innerProps} className="dial-code-option">
                {data.flag}
                <span>{data.dial_code}</span>
            </div>
        );
    }

    useEffect(() => {
        if (country) {
            getCities(country)
        }
    }, [country])

    useEffect(() => {
        getCountries()
        getDialCode(CountryList.getList())
    }, [])

    useEffect(() => {
        if (ProfileId == 'undefined') {
            setProfileId(null)
        } else {
            setProfileId(JSON.parse(ProfileId))
        }
    }, [ProfileId])

    return (
        <React.Fragment>
            <h1>Create Profile</h1>
            <StepIndicators step={2} />
            <div className="d-flex justify-content-between mt-5">
                <h2>Are you a permanent resident abroad?</h2>
                <div>
                    <ToggleSwitch condition={toggle} setCondition={setToggle} />
                </div>
            </div>

            {
                toggle ?
                    <React.Fragment>
                        <form onSubmit={handleSubmit(onSubmit)} className="w-100" autoComplete='off'>
                            <Row>
                                <Col sm={6}>
                                    <fieldset className={"input" + (errors?.CountryId ? ' invalid' : '')}>
                                        <legend>Select country</legend>
                                        <Controller
                                            name={"CountryId"}
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange, onBlur } }) => {
                                                return (<Select
                                                    className={"select-box"}
                                                    options={countries ? countries : []}
                                                    placeholder={""}
                                                    onChange={onChange}
                                                    value={value}
                                                    defaultValue={value}
                                                />)
                                            }}
                                        />
                                    </fieldset>
                                </Col>
                                <Col sm={6}>
                                    <fieldset className={"input" + (errors?.CityId ? ' invalid' : '')}>
                                        <legend>City</legend>
                                        <Controller
                                    name={'CityId'}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <ApiSearchBox
                                                {...field}
                                                endpointUrl={API_URL.COMMON.GET_CITIES}
                                                endpointMethod="POST"
                                                body={{
                                                    CountryId: country,
                                                    Name: '{{value}}',
                                                }}
                                                parserFunction={(data) => {
                                                    return (data as Record<string, string>[]).map(
                                                        (el) => {
                                                            return {
                                                                label: el.xql_name,
                                                                value: el.xql_cityid,
                                                            };
                                                        }
                                                    );
                                                }}
                                            />
                                        );
                                    }}
                                />
                                    </fieldset>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={6}>
                                    <InputField id='Passport' label='Passport' required={!!expDateWatch} register={register} errors={errors} />
                                </Col>
                                <Col sm={6}>
                                    <InputField
                                        id='ExpiryDate'
                                        readOnly={!passportWatch}
                                        type="date" placeholder="YYYY-MM-DD"
                                        label='Expiry Date'
                                        required={!!passportWatch}
                                        register={register}
                                        errors={errors}
                                        registerParams={{
                                            validate: { isFuture: (value: string) => (passportWatch ? isFuture(new Date(value)) : true) },
                                            pattern: {
                                                value: DATE_MM_DD_YYYY_PATTERN,
                                                message: 'Date must be in YYYY-MM-DD format',
                                            },
                                        }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <fieldset className={"input" + (errors?.Address ? ' invalid' : '')}>
                                        <legend>Address</legend>
                                        <textarea className="" {...register("Address", { required: false, maxLength: 80 })} />
                                    </fieldset>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={5}>
                                    <fieldset className={"input" + (errors?.DialCode ? ' invalid' : '')}>
                                        <legend>Code</legend>
                                        <Controller
                                            name={"DialCode"}
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange, onBlur } }) => {
                                                return (
                                                    // TODO: Restore flags
                                                    <Select
                                                        components={{ Option: dialCodeOptionUI }}
                                                        className={"select-box"}
                                                        options={dialCode ? dialCode : []}
                                                        placeholder={""}
                                                        onChange={onChange}
                                                        value={value}
                                                        defaultValue={value}

                                                    />
                                                )
                                            }}
                                        />

                                    </fieldset>
                                </Col>
                                <Col sm={7}>
                                    <fieldset className={"input" + (errors?.Telephone ? ' invalid' : '')}>
                                        <legend>Phone number</legend>
                                        <input type="text" className="" {...register("Telephone", { required: true, maxLength: 25 })} onKeyPress={(event: any) => handleKeyPressNumber(event)} />
                                    </fieldset>
                                    {errors?.Telephone ? <div className="invalid text-danger">
                                        {errors.Telephone.type === "required" ? 'Phone Number is Required' : null}
                                        {errors.Telephone.type === "maxLength" ? 'Phone Number should be of 10 digit' : null}
                                    </div> : null}
                                </Col>
                            </Row>
                            <div className="mt-4 d-flex gap-2 login-btns">
                                <Button className="btn" variant="success" type='submit'>Save & continue</Button>
                                <Button onClick={() => goBack()} className="btn btn-transparent" variant="secondary">Back</Button>
                            </div>
                        </form>
                    </React.Fragment>
                    :

                    <div className="mt-4 d-flex gap-2 login-btns">
                        <Link to='/signup?step=3'><Button variant='success'>Skip</Button></Link>
                        <Button onClick={() => goBack()} className="btn btn-transparent" variant="secondary">Back</Button>
                    </div>
            }

        </React.Fragment>
    )
}