import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import StepIndicators from './stepIndicators';
import * as CountryList from 'country-list-with-dial-code-and-flag';
import { handleKeyPressNumber } from '../../_common/functions';
import { InputField } from '../InputField';
import { DATE_MM_DD_YYYY_PATTERN, THIRTEEN_DIGITS_ONLY_PATTERN } from '../../_common/constants';
import { SelectOption } from '../../_common/types';
import { css } from '@emotion/css';
import Select from '../blocks/Select';
import * as saIdParser from 'south-african-id-parser';
import { format, isFuture } from 'date-fns';
import { PasswordToggle } from '../PasswordToggle';

interface incomingProps {
    onSubmitAddressForm?: any;
    tab?: any
}

export default function Step1({ onSubmitAddressForm, tab }: incomingProps) {
    const { register, handleSubmit, formState: { errors }, control, watch, setValue } = useForm();
    const userApi = useUserApi()
    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader()
    const navigate = useNavigate()

    const passportWatch = watch('Passport');
    const expDateWatch = watch('ExpiryDate');

    const [gender, setGender] = useState<SelectOption[]>([])
    const [dialCode, setDialCode] = useState<SelectOption[]>([])
    // const [saIdNumber, setSaIdNumber] = useState('')
    // const [selected, setSelected] = useState("")

    const onSubmit = (data: any) => {
        // console.log({ data }); return;
        if (data.Password === data.ConfirmPassword) {
            let params = {
                "FirstName": data.FirstName,
                "LastName": data.LastName,
                "IDNumber": data.IDNumber,
                "DateOfBirth": data.DateOfBirth.split("-").join("/"),
                "Gender": parseInt(data.Gender, 10),
                "DialCode": data.DialCode,
                "MobileNumber": data.MobileNumber,
                "Email": data.Email,
                "Password": data.Password,
                "Passport": data.Passport,
                "ExpiryDate": data.ExpiryDate.split("-").join("/"),
            }
            showLoader();
            userApi.registerStepOne(params, (message: string, resp: any) => {
                console.log({ resp })
                hideLoader();
                toast.success(message)
                navigate('/signup?step=2')
            }, (message: string) => {
                toast.error(message)
                hideLoader();
            });
        } else {
            toast.error(`Password and Confirm password should be same`)
        }
    }

    const retrieveGender = () => {
        commonApi.retrieveGender({ "DropdownName": "xql_gender" },
            (message: string, resp: any) => {
                let options: any = [];
                if (resp.Options && resp.Options.length > 0) {
                    resp.Options.map((item: any) => {
                        item.value = item.Value
                        item.label = item.Label.UserLocalizedLabel.Label
                        options.push(item)
                    })
                    setGender(options)
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const getDialCode = (data: any) => {
        if (data.length > 0) {
            setDialCode(data.map((item: any) => {
                item.label = `(${item.dial_code}) ${item.name}`;
                item.value = item.dial_code;
                return item;
            }).sort((a: SelectOption, b: SelectOption) => Number(a.value) - Number(b.value)))
        }
    }

    const dialCodeOptionUI = (props: any) => {
        const { innerProps, innerRef, data } = props;
        return (
            <div ref={innerRef} {...innerProps} className="dial-code-option">
                {data.flag}
                <span>{data.dial_code}</span>
            </div>
        );
    }

    useEffect(() => {
        retrieveGender()
        getDialCode(CountryList.getList())
    }, [])

    const saIdNumber = watch('IDNumber');


    useEffect(() => {
        const data = saIdParser.parse(saIdNumber);
        if (data.isValid) {
            setValue('DateOfBirth', format(data.dateOfBirth, 'yyyy-MM-dd'))
            setValue('Gender', data.isMale ? "0" : "1") // TODO: Consult about "Other" gender
        }
        console.log({ data })
    }, [saIdNumber])

    return (
        <React.Fragment>

            <div className="w-100">
                <h1>Create Profile</h1>
                <StepIndicators step={1} />
                <form onSubmit={handleSubmit(onSubmit)} className='w-100 pr-5' autoComplete='off'>
                    <div className="container-fluid p-0">
                        <Row>
                            <Col sm={6}>
                                <fieldset className={"input" + (errors?.FirstName ? ' invalid' : '')}>
                                    <legend>First name</legend>
                                    <input type="text" className="" placeholder='' {...register("FirstName", { required: true, maxLength: 80 })} />
                                </fieldset>
                            </Col>
                            <Col sm={6}>
                                <fieldset className={"input" + (errors?.LastName ? ' invalid' : '')}>
                                    <legend>Last name</legend>
                                    <input type="text" className="" {...register("LastName", { required: true, maxLength: 80 })} />
                                </fieldset>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <InputField id='IDNumber' label='SA ID Number' register={register} errors={errors} registerParams={{
                                    pattern: {
                                        value: THIRTEEN_DIGITS_ONLY_PATTERN,
                                        message: 'Enter a valid 13-digit national ID number',
                                    },
                                }} />
                            </Col>
                            <Col sm={6}>
                                <InputField id='DateOfBirth' type="date" placeholder="YYYY-MM-DD" label='Date of Birth' register={register} errors={errors} registerParams={{
                                    pattern: {
                                        value: DATE_MM_DD_YYYY_PATTERN,
                                        message: 'Date must be in YYYY-MM-DD format',
                                    },

                                }} />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <InputField id='Passport' label='Passport' required={!!expDateWatch} register={register} errors={errors} />
                            </Col>
                            <Col sm={6}>
                                <InputField
                                    id='ExpiryDate'
                                    readOnly={!passportWatch}
                                    required={!!passportWatch}
                                    type="date"
                                    placeholder="YYYY-MM-DD"
                                    label='Expiry Date'
                                    register={register}
                                    errors={errors}
                                    registerParams={{
                                        validate: { isFuture: (value: string) => ((passportWatch ? isFuture(new Date(value)) : true)) },
                                        pattern: {
                                            value: DATE_MM_DD_YYYY_PATTERN,
                                            message: 'Date must be in YYYY-MM-DD format',
                                        },
                                    }} />
                            </Col>
                        </Row>
                        <Row>


                            <Col sm={6}>
                                <fieldset className={"input" + (errors?.Gender ? ' invalid' : '')}>
                                    <legend>Gender</legend>
                                    <Controller
                                        name={"Gender"}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, onBlur } }) => {
                                            return (<Select
                                                className={"select-box"}
                                                options={gender ? gender : []}
                                                placeholder={""}
                                                onChange={onChange}
                                                value={value}
                                                defaultValue={value}
                                            />)
                                        }}
                                    />
                                </fieldset>
                            </Col>
                            <Col sm={6}>
                                <fieldset className={"input" + (errors?.Email ? ' invalid' : '')}>
                                    <legend>Email</legend>
                                    <input type="email" className="" placeholder='' autoComplete="off" {...register("Email", { required: true, maxLength: 80, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                                </fieldset>
                                {errors?.Email ? <div className="invalid text-danger">
                                    <sup>
                                        {errors.Email.type === "required" ? 'Email Required' : null}
                                        {errors.Email.type === "pattern" ? 'Invalid email address' : null}</sup>
                                </div> : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Row>
                                    <Col sm={5}>
                                        <fieldset className={"input" + (errors?.DialCode ? ' invalid' : '')}>
                                            <legend>Code</legend>
                                            {/* <ReactFlagsSelect
                                                selected={selected}
                                                onSelect={(code) => setSelected(code)}
                                            /> */}
                                            <Controller
                                                name={"DialCode"}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange, onBlur } }) => {
                                                    return (
                                                        // TODO: Restore flags
                                                        <Select
                                                            components={{ Option: dialCodeOptionUI }}
                                                            className={"select-box"}
                                                            options={dialCode ? dialCode : []}
                                                            placeholder={""}
                                                            onChange={onChange}
                                                            value={value}
                                                            defaultValue={value}

                                                        />
                                                    )
                                                }}
                                            />
                                        </fieldset>
                                    </Col>
                                    <Col sm={7}>
                                        <fieldset className={"input" + (errors?.MobileNumber ? ' invalid' : '')}>
                                            <legend>Cellphone</legend>
                                            <input type="text" className="" {...register("MobileNumber", { required: true, maxLength: 15 })} onKeyPress={(event: any) => handleKeyPressNumber(event)} />
                                        </fieldset>
                                        {errors?.MobileNumber ? <div className="invalid text-danger"><sup>
                                            {errors.MobileNumber.type === "required" ? 'Phone Number is Required' : null}
                                            {errors.MobileNumber.type === "maxLength" ? 'Phone Number should be of 10 digit' : null} </sup>
                                        </div> : null}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>

                            <Row>
                                <PasswordToggle sm register={register} errors={errors} label={'Password'} id={'Password'} />

                                <PasswordToggle sm register={register} errors={errors} label={'Confirm Password'} id={'ConfirmPassword'} />

                            </Row>
                        </Row>
                    </div>

                    <div className="mt-4 d-flex gap-2 login-btns">
                        <Button variant='success' className={css({ background: '#005D28 !important', color: '#fff !important' })} type='submit'>Save & continue</Button>
                        <Link to="/welcome"><Button className="btn btn-transparent" variant="secondary">Back</Button></Link>
                    </div>
                </form>
            </div >
        </React.Fragment >
    )
}