import React, { useState, useEffect } from 'react';
import { Button, Container, ListGroup } from 'react-bootstrap';
import { BsFolder2 } from "react-icons/bs";
import { Link } from 'react-router-dom';
import TravelNotification from './travelNotification';
import "../../styles/components/travels/travelList.scss";
import { css } from '@emotion/css';

interface incomingProps {
    data: any;
    pastTravels: any;
    upcomingTravels: any;
}

const tabList = [
    {
        id: 'upcoming',
        label: "Upcoming"
    },
    {
        id: 'past',
        label: "Past"
    }
];

export default function TravelList({ data, upcomingTravels, pastTravels }: incomingProps) {


    const [tabId, setTabId] = useState('upcoming')

    const handelClick = (e: any) => {
        setTabId(e.target.id);
    }

    // console.log({data})

    return (
        <div className='main-div'>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="page-heading mb-0">Travels</h2>
                <Link to='/new-travel'><Button className='btn btn-success'>Create Travel</Button></Link>
            </div>
            <ListGroup className="cases-tab mb-3 justify-content-around" onClick={(e) => handelClick(e)}>
                {
                    tabList.map((item, i) => (
                        <ListGroup.Item key={i} className={tabId == item.id ? "case-tab-li active" : "case-tab-li"} id={item.id}>{item.label}</ListGroup.Item>
                    ))
                }
            </ListGroup>

            {
                tabId == 'upcoming' && upcomingTravels.length === 0 &&
                <>
                    <div className={css({
                        width: '100%',
                        minHeight: '300px',
                        marginTop: '80px'
                    })}>
                        <div className="d-flex justify-content-center align-items-center" >
                            <div className={css({
                                textAlign: 'center',
                                width: '300px'
                            })}>
                                <div className={css({ fontSize: '40px' })}><BsFolder2 /></div>
                                <div className={css({ fontWeight: 'bold', fontSize: '1.2rem', marginBottom: '8px' })}>No travels</div>
                                <div>Get started by creating a new travel</div>
                            </div>
                        </div>
                    </div>
                </>
            }

            {tabList.map((item, i) => (
                <div className={"childdiv flex-wrap"} key={i}>
                    {tabId == 'upcoming' && item.id == 'upcoming' && upcomingTravels.length > 0 ?
                        upcomingTravels.map((item: any, idx: any) => (
                            <div className='single-travel' key={idx}>
                                <TravelNotification data={item} />
                                <br />
                            </div>
                        ))
                        : null
                    }
                    {tabId == 'past' && item.id == 'past' ?
                        pastTravels.map((item: any, idx: any) => (
                            <div className='single-travel' key={idx}>
                                <TravelNotification data={item} />
                                <br />
                            </div>
                        ))
                        : null
                    }
                </div>
            ))}
            <br />

        </div>
    )
}