import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Header from '../components/header'
import { Row, Col } from 'react-bootstrap';
import '../styles/travelcity.scss';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { Link } from 'react-router-dom';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import LeftPanel from '../components/leftPanel';
import { IMAGE_ASSETS } from '../assets';

const TravelCity = () => {

    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader()

    let CityId: any = sessionStorage.getItem("CityId")

    const [data, setData] = useState<any>(null)
    const [cityId, setCityId] = useState<any>(null)

    const getCityAlert = () => {
        showLoader()
        commonApi.getCityAlert({
            // city: "a7617a2b-955e-ed11-9561-000d3a4bbc07"
            city: cityId
        }, (message: string, resp: any) => {
            hideLoader()
            console.log({ resp })
            setData(resp)
        }, (message: string) => {
            hideLoader()
            console.log({ message })
        })
    }

    useEffect(() => {
        if (CityId) {
            getCityAlert()
        }
    }, [cityId])

    useEffect(() => {
        if (CityId == 'undefined') {
            setCityId(null)
        } else {
            // setCityId(CityId)
            setCityId(JSON.parse(CityId))
        }
    }, [CityId])

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="wrapper-left-right-panel">
                    <LeftPanel img={IMAGE_ASSETS.travelSuitcases} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."} />
                    <div className="dashboard">
                        <Container className="dashboard-container">
                            <div className="main-div travelcity-list">
                                <h2>Your trip has been added.</h2>
                                <p>For more information visit the country/city details below.</p>
                                {/* <div className="travelcity_listtxt">
                            Do be aware of your surroundings, and watch<br />
                            for suspicious people or vehicles.
                        </div> */}
                                <Row>
                                    <Col md={12}>
                                        <div className="card mb-3">
                                            <div className="card-body d-flex align-items-center">
                                                <div className="citylist_img">
                                                    {data?.Images && data?.Images.length > 0 ?
                                                        <img src={data?.Images[0]} alt="" />
                                                        : null
                                                    }
                                                </div>
                                                <div className="citylist_right">
                                                    <h4>{data?.Name}</h4>
                                                    <Link to="/travels-city-info"><img className='pr-2' src="/assets/img/bell.svg" alt="" /> {data?.NoOfAlerts} Active Alerts <img className="ms-auto" src='/assets/img/right_angle.svg' alt='' /></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Link className="btn btn-success" to="/travels" onClick={() =>
                                    sessionStorage.removeItem("CityId")}>Dismiss</Link>
                            </div>
                        </Container>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default TravelCity