import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import Select from 'react-select';
import * as CountryList from 'country-list-with-dial-code-and-flag';
import Header from '../../components/header';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import { handleKeyPressNumber } from '../../_common/functions';
import { ApiSearchBox } from '../../components/ApiSearchbox';
import { API_URL } from '../../_config';


export default function KinDetails() {

    const { register, handleSubmit, formState: { errors }, control, reset, watch, getValues } = useForm()
    const country = watch('CountryId');
    let ProfileId: any = sessionStorage.getItem("ProfileId")

    const userApi = useUserApi()
    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader()
    const navigate = useNavigate()
    const user: any = useUserSelector()

    const [hospitalizationToggle, setHospitalizationToggle] = useState<boolean>(false)
    const [deathToggle, setDeathToggle] = useState<boolean>(false)
    const [incarcerationToggle, setIncarcerationToggle] = useState<boolean>(false)
    const [relationship, setRelationship] = useState<any>(null)
    const [countries, setCountries] = useState<any>(null)
    const [cities, setCities] = useState<any>(null)
    const [dialCode, setDialCode] = useState<any>(null)
    const [setPrevCityId, set_setPrevCityId] = useState<any>(null)
    const [profileId, setProfileId] = useState<any>(null)
    const [nextOfKinId, setNextOfKinId] = useState<string>('')


    const onSubmit = (data: any) => {
        let params = {
            "EntityName": "NextOfKin",
            "ContactId": user.contactid,
            "FirstName": data.FirstName,
            "LastName": data.LastName,
            "Email": data.Email,
            "DialCode": data.DialCode.value,
            "CellPhone": data.CellPhone,
            "CountryId": data.CountryId.value,
            "CityId": data.CityId.value,
            "RelationshipType": data.RelationshipType.value,
            "Address": data.Address,
            "Hospitalization": hospitalizationToggle,
            "Death": deathToggle,
            "Incarceration": incarcerationToggle,
            "NextOfKinId": nextOfKinId
        }
        console.log({ params })
        showLoader();
        userApi.registerStepThree(params, (message: string, resp: any) => {
            // console.log({ resp })
            hideLoader();
            toast.success(message)
            navigate('/profile-settings')
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    const retrieveRelationShip = () => {
        commonApi.retrieveRelationShip({ "DropdownName": "xql_relationshiotype" },
            (message: string, resp: any) => {
                let options: any = [];
                if (resp.Options && resp.Options.length > 0) {
                    resp.Options.map((item: any) => {
                        item.value = item.Value
                        item.label = item.Label.UserLocalizedLabel.Label
                        options.push(item)
                    })
                    setRelationship(options)
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const getCountries = () => {
        commonApi.getCountries({},
            (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCountries(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_countryid;
                        return data;
                    }))
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const getCities = (id: any = country?.value) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.getCities({
                CountryId: id
            }, (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCities(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_cityid;
                        return data;
                    }))
                }
                setTimeout(() => {
                    resolve()
                }, 300)
            }, (message: string) => {
                // console.log(message);
                reject()
            });
        })
    }

    const getDialCode = (data: any) => {
        if (data.length > 0) {
            setDialCode(data.map((item: any) => {
                item.label = item.dial_code;
                item.value = item.dial_code;
                return item;
            }))
        }
    }

    const dialCodeOptionUI = (props: any) => {
        const { innerProps, innerRef, data } = props;
        return (
            <div ref={innerRef} {...innerProps} className="dial-code-option">
                {data.flag}
                <span>{data.dial_code}</span>
            </div>
        );
    }

    const retrieveProfile = () => {
        showLoader()
        userApi.retrieveProfile({ contact: user.contactid },
            (message: string, resp: any) => {
                console.log({ resp })
                setNextOfKinId(resp?.NextOfKin?.xql_nextofkinid)
                set_setPrevCityId(resp?.NextOfKin?._xql_cityid_value)
                reset({
                    FirstName: resp?.NextOfKin?.xql_firstname,
                    LastName: resp?.NextOfKin?.xql_lastname,
                    IDNumber: resp?.NextOfKin?.IDNumber,
                    CellPhone: resp?.NextOfKin?.xql_cellphone,
                    Email: resp?.NextOfKin?.xql_email,
                    RelationshipType: getSelectDataById(resp?.NextOfKin?.xql_relationship, relationship),
                    DialCode: getSelectedDialCodeById(resp?.NextOfKin?.xql_dialcode, dialCode),
                    CountryId: getSelectedCountryCityById(resp?.NextOfKin?._xql_countryid_value, countries, "xql_countryid"),
                    Address: resp?.NextOfKin?.xql_address,
                })
                //irregular data
                setDeathToggle(resp?.NextOfKin?.xql_death)
                setHospitalizationToggle(resp?.NextOfKin?.xql_hospitalization)
                setIncarcerationToggle(resp?.NextOfKin?.xql_incarceration)
                hideLoader()
            }, (message: string) => {
                hideLoader()
            })
    }

    const getSelectedDialCodeById = (id: any, data: any) => {
        let option = null
        data?.map((_option: any) => {
            if (_option.dial_code == id) {
                option = _option
            }
        })
        return option
    }

    const getSelectedCountryCityById = (id: any, data: any, key: any) => {
        // console.log({ key })
        let option = null
        data?.map((_option: any) => {
            if (_option[key] == id) {
                option = _option
            }
        })
        return option
    }

    const getSelectDataById = (id: any, data: any) => {
        let option = null
        data?.map((_option: any) => {
            if (_option.Value == id) {
                option = _option
            }
        })
        return option
    }

    useEffect(() => {
        if (user && relationship && dialCode && countries) {
            retrieveProfile()
        }
    }, [user, relationship, dialCode, countries])

    useEffect(() => {
        if (cities) {
            if (setPrevCityId) {
                let opt: any = getSelectedCountryCityById(setPrevCityId, cities, "xql_cityid");
                set_setPrevCityId(null)
                // console.log(opt)
                // console.log({...getValues(), ...{campus: opt}})
                reset({
                    ...getValues(), CityId: opt
                })
            }
        }
    }, [cities])

    useEffect(() => {
        if (country) {
            getCities()
        }
    }, [country])

    useEffect(() => {
        getCountries()
        retrieveRelationShip()
        getDialCode(CountryList.getList())
    }, [])

    useEffect(() => {
        if (ProfileId == 'undefined') {
            setProfileId(null)
        } else {
            setProfileId(JSON.parse(ProfileId))
        }
    }, [ProfileId])


    return (
        <React.Fragment>

            <Header backMode={{ pageName: "Personal Details", backUrl: "/profile-settings" }} />
            <Container>
                <div className="card-outer signup-wrapper-outer">
                    <div className="card">
                        <h1 className="text-center">Kin Details</h1>
                        <form onSubmit={handleSubmit(onSubmit)} className="w-100" autoComplete='off'>
                            <div className="container-fluid p-0">
                                <Row>
                                    <Col sm={6}>
                                        <fieldset className={"input" + (errors?.FirstName ? ' invalid' : '')}>
                                            <legend>First name</legend>
                                            <input type="text" className="" placeholder='' {...register("FirstName", { required: true, maxLength: 80 })} />
                                        </fieldset>
                                    </Col>
                                    <Col sm={6}>
                                        <fieldset className={"input" + (errors?.LastName ? ' invalid' : '')}>
                                            <legend>Last name</legend>
                                            <input type="text" className="" {...register("LastName", { required: true, maxLength: 80 })} />
                                        </fieldset>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <fieldset className={"input" + (errors?.RelationshipType ? ' invalid' : '')}>
                                            <legend>Relationship</legend>
                                            <Controller
                                                name={"RelationshipType"}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange, onBlur } }) => {
                                                    return (<Select
                                                        className={"select-box"}
                                                        options={relationship}
                                                        placeholder={""}
                                                        onChange={onChange}
                                                        value={value}
                                                        defaultValue={value}
                                                    />)
                                                }}
                                            />
                                        </fieldset>
                                    </Col>
                                    <Col sm={6}>
                                        <fieldset className={"input" + (errors?.Address ? ' invalid' : '')}>
                                            <legend>Address</legend>
                                            <input type="text" className="" {...register("Address", { required: true, maxLength: 80 })} />
                                        </fieldset>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <fieldset className={"input" + (errors?.CountryId ? ' invalid' : '')}>
                                            <legend>Select country</legend>
                                            <Controller
                                                name={"CountryId"}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange, onBlur } }) => {
                                                    return (<Select
                                                        className={"select-box"}
                                                        options={countries ? countries : []}
                                                        placeholder={""}
                                                        onChange={onChange}
                                                        value={value}
                                                        defaultValue={value}
                                                    />)
                                                }}
                                            />
                                        </fieldset>
                                    </Col>
                                    <Col sm={6}>
                                        <fieldset className={"input" + (errors?.CityId ? ' invalid' : '')}>
                                            <legend>City</legend>
                                            <Controller
                                    name={'CityId'}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <ApiSearchBox
                                                {...field}
                                                endpointUrl={API_URL.COMMON.GET_CITIES}
                                                endpointMethod="POST"
                                                body={{
                                                    CountryId: country,
                                                    Name: '{{value}}',
                                                }}
                                                parserFunction={(data) => {
                                                    return (data as Record<string, string>[]).map(
                                                        (el) => {
                                                            return {
                                                                label: el.xql_name,
                                                                value: el.xql_cityid,
                                                            };
                                                        }
                                                    );
                                                }}
                                            />
                                        );
                                    }}
                                />
                                        </fieldset>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <fieldset className={"input" + (errors?.Email ? ' invalid' : '')}>
                                            <legend>Email</legend>
                                            <input type="email" className="" placeholder='' {...register("Email", { required: true, maxLength: 80, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                                        </fieldset>
                                        {errors?.Email ? <div className="invalid text-danger">
                                            {errors.Email.type === "required" ? 'Email Required' : null}
                                            {errors.Email.type === "pattern" ? 'Invalid email address' : null}
                                        </div> : null}
                                    </Col>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={5}>
                                                <fieldset className={"input" + (errors?.DialCode ? ' invalid' : '')}>
                                                    <legend>Code</legend>
                                                    <Controller
                                                        name={"DialCode"}
                                                        control={control}
                                                        rules={{ required: true }}
                                                        render={({ field: { value, onChange, onBlur } }) => {
                                                            return (<Select
                                                                components={{ Option: dialCodeOptionUI }}
                                                                className={"select-box"}
                                                                options={dialCode ? dialCode : []}
                                                                placeholder={""}
                                                                onChange={onChange}
                                                                value={value}
                                                                defaultValue={value}
                                                            />)
                                                        }}
                                                    />
                                                </fieldset>
                                            </Col>
                                            <Col sm={7}>
                                                <fieldset className={"input" + (errors?.CellPhone ? ' invalid' : '')}>
                                                    <legend>Phone number</legend>
                                                    <input type="text" className="" {...register("CellPhone", { required: true, minLength: 10, maxLength: 10 })} onKeyPress={(event: any) => handleKeyPressNumber(event)} />
                                                </fieldset>
                                                {errors?.CellPhone ? <div className="invalid text-danger">
                                                    {errors.CellPhone.type === "required" ? 'Phone Number is Required' : null}
                                                    {errors.CellPhone.type === "maxLength" ? 'Phone Number should be of 10 digit' : null}
                                                    {errors.CellPhone.type === "minLength" ? 'Phone Number should be of 10 digit' : null}
                                                </div> : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                {/* <h6>I authorize Contoso to contact designated next of kin, in any or all of the following cases (please select):</h6> */}
                            </div>
                            <div className="d-flex justify-content-around align-items-center gap-3 toggle-btns">
                                <div className="form-check form-switch d-flex align-items-center">
                                    <label className="form-check-label mb-0 pe-5 me-3" htmlFor="flexSwitchCheckChecked">Hospitalisation</label>
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={() => setHospitalizationToggle(!hospitalizationToggle)} checked={hospitalizationToggle} />

                                </div>
                                <div className="form-check form-switch d-flex align-items-center">
                                    <label className="form-check-label mb-0 pe-5 me-3" htmlFor="flexSwitchCheckCheckedTwo">Death</label>
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedTwo" onChange={() => setDeathToggle(!deathToggle)} checked={deathToggle} />

                                </div>
                                <div className="form-check form-switch d-flex align-items-center">
                                    <label className="form-check-label mb-0 pe-5 me-3" htmlFor="flexSwitchCheckCheckedThree">Incarceration</label>
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedThree" onChange={() => setIncarcerationToggle(!incarcerationToggle)} checked={incarcerationToggle} />

                                </div>
                            </div>
                            <div className="mt-4 d-flex gap-2 login-btns">
                                <Button variant='success' type='submit'>Save</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}