import React from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { useUserApi } from "../_common/hooks/api/UserApiHook"
import { Row, Col, Button } from 'react-bootstrap'
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from 'react-router-dom'
import internationalRelationsLogo from '../assets/icons/logo_old.png'
import { css } from '@emotion/css'

import '../styles/login.scss';
import { toast } from "react-hot-toast";
import { DesktopLandingContainer } from "../components/DesktopLandingContainer";
import { MobileLandingContainer } from "../components/MobileLandingContainer";
import { useMobile } from "../_common/hooks/useMobile";
import { PasswordToggle } from "../components/PasswordToggle";

export default function ResetPassword() {
	const navigate = useNavigate();
	const { showLoader, hideLoader } = useAppLoader();
	const userApi = useUserApi();
	const { register, handleSubmit, formState: { errors } } = useForm();
	const isMobile = useMobile();

	const { id: Code } = useParams()

	const onSubmit = (data: any) => {
		if (data.Password === data.ConfirmPassword) {
			let params = {
				Code,
				Password: data.Password
			}
			showLoader();
			userApi.resetPassword(params, (message: string, resp: any) => {
				hideLoader();
				toast.success(message)
				navigate('/login')
			}, (message: string) => {
				toast.error(message)
				hideLoader();
			});
		} else {
			toast.error(`Password and Confirm password should be same`)
		}
	}

	return isMobile ?
		(<MobileLandingContainer>
			<>
				<div>
					<h2 className={css({ color: 'white', textAlign: 'center', marginBottom: 40 })}>Reset Password</h2>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<PasswordToggle isAuthMobile register={register} errors={errors} label={'Password'} id={'Password'} />
						</Row>
						<Row>
							<PasswordToggle isAuthMobile register={register} errors={errors} label={'Confirm Password'} id={'ConfirmPassword'} />
						</Row>

						<div className="mobbottom_fixbtn">
							<Button variant="success" className="w-100 mt-2" type="submit">Reset</Button>
						</div>
					</form>

				</div>
			</>
		</MobileLandingContainer>)
		: (
			<DesktopLandingContainer>
				<div className={css({ width: '25em' })}>
					<div className="d-flex justify-content-center gap-3">
						<div className="logo">
							<img src={internationalRelationsLogo} alt="Department of InternationalRelations Logo" />
						</div>
					</div>
					<div className="w-100">
						<div className="text-center">
							<h2 className="d-inline-block mt-5 ">Welcome to DIRCO</h2>
							<h3>Enter your details to proceed further</h3>
						</div>

						<form onSubmit={handleSubmit(onSubmit)}>

							<Row>
								<PasswordToggle register={register} errors={errors} label={'Password'} id={'Password'} />

							</Row>
							<Row>
								<Col sm={12}>
									<PasswordToggle register={register} errors={errors} label={'Confirm Password'} id={'ConfirmPassword'} />
								</Col>
							</Row>

							<div className="mobbottom_fixbtn">
								<Button variant="success" className="w-100 mt-2" type="submit">Reset</Button>
							</div>
						</form>
					</div>
				</div>
			</DesktopLandingContainer>
		);
}

