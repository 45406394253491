interface ChildProps {
   condition: boolean;
   setCondition: React.Dispatch<React.SetStateAction<boolean>>
}
 
 const ToggleSwitch = ({condition, setCondition}:ChildProps) => {

    return(
        <div>
            <div className="form-check form-switch" >
            <input className="form-check-input modal-check-input" type="checkbox" role="switch" checked={condition} id="modalSwitchDefault" onChange={(e) => {setCondition(!condition)}} />
            <label className="form-check-label" htmlFor="modalSwitchDefault"></label>
            </div>
       </div>
    ) 
}

export default ToggleSwitch;