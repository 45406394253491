import React from 'react';
import '../styles/welcome.scss';
import { Link } from 'react-router-dom';
import { css } from '@emotion/css';
import internationalRelationsLogo from '../assets/icons/logo_old.png';

import { DesktopLandingContainer } from '../components/DesktopLandingContainer';
import { MobileLandingContainer } from '../components/MobileLandingContainer';
import { useMobile } from '../_common/hooks/useMobile';

export default function EntryType() {
  const isMobile = useMobile();

  return isMobile ? (
    <MobileLandingContainer>
      <div className={css({ flex: 1 })} />
      <div>
        <div className="w-100">
          <div className="text-center">&nbsp;</div>

          <div className="mt-4 w-100 login-btns">
            <Link to="/login" className="btn btn-success w-100 mb-2">
              Sign in
            </Link>
            <Link to="/privacy-policy" className="btn w-100">
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </MobileLandingContainer>
  ) : (
    <DesktopLandingContainer>
      <div className={css({ width: '25em' })}>
        <div className="d-flex justify-content-center gap-3">
          <div className="logo">
            <img src={internationalRelationsLogo} alt='Department of International Relations Logo'/>
          </div>
        </div>
        <div className="w-100">
          <div className="text-center">
            <h2 className="d-inline-block mt-5 ">Welcome to DIRCO</h2>
          </div>

          <div className="mt-4 w-100 login-btns">
            <Link to="/login" className="btn btn-success w-100 mb-2">
              Sign in
            </Link>
            <Link to="/privacy-policy" className="btn w-100">
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </DesktopLandingContainer>
  );
}
