import { takeLatest, all, takeEvery } from 'redux-saga/effects'; // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from '../../_config';

import * as userSaga from './userSaga';
import * as commonSaga from './commonSaga';
import * as travelsSaga from './travelsSaga';
import * as caseSaga from './caseSaga';
import * as advisorySaga from './advisorySaga';
import * as countryDirectorySaga from './countryDirectorySaga';

export function* sagas() {
  yield all([
    takeEvery(SAGA_ACTIONS.COMMON.GENERIC_POST, commonSaga.genericPost),
    takeEvery(SAGA_ACTIONS.COMMON.GENERIC_GET, commonSaga.genericGet),

    takeLatest(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
    takeLatest(SAGA_ACTIONS.USER.REFRESH_TOKEN, userSaga.refreshToken),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout),
    takeLatest(SAGA_ACTIONS.USER.PRIVACY_POLICY, userSaga.getprivacyPolicy),
    takeLatest(SAGA_ACTIONS.USER.REGISTER.STEP1, userSaga.registerStepOne),
    takeLatest(SAGA_ACTIONS.USER.REGISTER.STEP2, userSaga.registerStepTwo),
    takeLatest(SAGA_ACTIONS.USER.REGISTER.STEP3, userSaga.registerStepThree),
    takeLatest(SAGA_ACTIONS.USER.REGISTER.STEP4, userSaga.registerStepFour),
    takeLatest(SAGA_ACTIONS.USER.RETRIEVE_PROFILE, userSaga.retrieveProfile),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, userSaga.forgotPassword),
    takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, userSaga.resetPassword),
    takeLatest(SAGA_ACTIONS.USER.RETRIEVE_PASSPORTS, userSaga.retrievePassports),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE, userSaga.updateProfile),

    takeEvery(SAGA_ACTIONS.COMMON.POST, commonSaga.post),
    takeEvery(SAGA_ACTIONS.COMMON.GET, commonSaga.get),
    takeEvery(SAGA_ACTIONS.COMMON.GET_COUNTRIES, commonSaga.getCountries),
    takeEvery(SAGA_ACTIONS.COMMON.GET_CITIES, commonSaga.getCities),
    takeEvery(SAGA_ACTIONS.COMMON.RETRIEVE_RELATIONSHIP, commonSaga.retrieveRelationShip),
    takeEvery(SAGA_ACTIONS.COMMON.RETRIEVE_GENDER, commonSaga.retrieveGender),
    takeEvery(SAGA_ACTIONS.COMMON.GET_GEO_SERVICE, commonSaga.getGeoService),
    takeEvery(SAGA_ACTIONS.COMMON.RETRIEVE_LODGE_TYPE, commonSaga.retrieveLodgeType),
    takeEvery(SAGA_ACTIONS.COMMON.RETRIEVE_TRAVEL_REASON, commonSaga.retrieveTravelReason),
    takeEvery(SAGA_ACTIONS.COMMON.GET_CITY_ALERT, commonSaga.getCityAlert),
    takeEvery(SAGA_ACTIONS.COMMON.COUNTRY_INFO, commonSaga.getCountryInfo),
    takeEvery(SAGA_ACTIONS.COMMON.CITY_INFO, commonSaga.getCityInfo),

    takeEvery(SAGA_ACTIONS.TRAVELS.GET_TRAVELS, travelsSaga.getTravels),
    takeEvery(SAGA_ACTIONS.TRAVELS.TRAVELS_DETAILS, travelsSaga.getTravelsDetails),
    takeEvery(SAGA_ACTIONS.TRAVELS.STEP1, travelsSaga.createTravelStepOne),
    takeEvery(SAGA_ACTIONS.TRAVELS.STEP2, travelsSaga.createTravelStepTwo),
    takeEvery(SAGA_ACTIONS.TRAVELS.STEP3, travelsSaga.createTravelStepThree),
    takeEvery(SAGA_ACTIONS.TRAVELS.STEP4, travelsSaga.createTravelStepFour),

    takeEvery(SAGA_ACTIONS.CASES.GET_CASES, caseSaga.getCases),
    takeEvery(SAGA_ACTIONS.CASES.CASE_DETAILS, caseSaga.getCasesDetails),
    takeEvery(SAGA_ACTIONS.CASES.GET_CASES_TYPES, caseSaga.getCasesTypes),
    takeEvery(SAGA_ACTIONS.CASES.CREATE_CASE, caseSaga.createCase),
    takeEvery(SAGA_ACTIONS.CASES.CREATE_CASE_STEP2, caseSaga.createCaseStepTwo),
    takeEvery(SAGA_ACTIONS.CASES.CREATE_CASE_DOC_UPLOAD, caseSaga.createCaseDocUpload),

    takeEvery(SAGA_ACTIONS.ADVISORY.CREATE_ALERT, advisorySaga.createAlert),
    takeEvery(SAGA_ACTIONS.ADVISORY.GET_CONTACT_ALERT, advisorySaga.getContactAlert),
    takeEvery(SAGA_ACTIONS.ADVISORY.RETRIEVE_ALERT_TYPE, advisorySaga.retrieveAlertType),
    takeEvery(SAGA_ACTIONS.ADVISORY.GET_ALERT_BY_TYPE, advisorySaga.getAlertByType),

    takeEvery(SAGA_ACTIONS.COUNTRY_DIRECTORY.POPULAR_COUNTRIES, countryDirectorySaga.getPopularCountries),
    takeEvery(SAGA_ACTIONS.COUNTRY_DIRECTORY.POPULAR_CITIES, countryDirectorySaga.getPopularCities),

  ]);
}