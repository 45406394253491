 

export const MediaQuery = (breakpoint: 'sm' | 'md' | 'lg' | 'xl') => {
    switch(breakpoint) {
        case 'sm':
            return `@media screen and  (max-width: ${767}px)`;
        case 'md':
            return `@media screen and  (min-width: ${768}px)`;
        case 'lg':
            return `@media screen and  (min-width: ${992}px)`;
        case 'xl':
            return `@media screen and  (min-width: ${1200}px)`;
    }
}