import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE, REFRESH_TOKEN_STORAGE, ACCESS_TOKEN_STORAGE, CONTACT_ID_STORAGE,USER_ID_STORAGE } from '../../_config'
import { CallApi } from './api/callApi';
const API_BASE_URL = process.env.REACT_APP_API_URL;

export function* login(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data, false);
         if (resp.status >= 200 && resp.status < 300 ) {
             sessionStorage.setItem(STORAGE, JSON.stringify(resp.data));
             localStorage.setItem(REFRESH_TOKEN_STORAGE, resp.data.refresh_token);
             localStorage.setItem(ACCESS_TOKEN_STORAGE, resp.data.access_token);
             localStorage.setItem(CONTACT_ID_STORAGE, resp.data.response.contactid);
             localStorage.setItem(USER_ID_STORAGE, data.Email);
             yield put({
                 type: ACTIONS.USER.LOGIN,
                 payload: {
                     user:  resp.data,
                     token: null,
                 }
             })

             action && action.callbackSuccess && action.callbackSuccess(resp.data);


         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* forgotPassword(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.FORGOT_PASSWORD, data, false);
         if (resp.status >= 200 && resp.status < 300 ) {
             yield put({
                 type: ACTIONS.USER.FORGOT_PASSWORD,
                 payload: {
                     user:  resp.data,
                     token: null,
                 }
             })

             action && action.callbackSuccess && action.callbackSuccess(resp.data);


         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* resetPassword(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.RESET_PASSWORD, data, false);
         if (resp.status >= 200 && resp.status < 300 ) {
             yield put({
                 type: ACTIONS.USER.RESET_PASSWORD,
                 payload: {
                     user:  resp.data,
                     token: null,
                 }
             })

             action && action.callbackSuccess && action.callbackSuccess(resp.data);


         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* refreshToken(action: any): any {
    try {
        const resp = yield call(CallApi.POST, API_URL.USER.REFRESH_TOKEN, {
            Email: localStorage.getItem(USER_ID_STORAGE),
            Token: localStorage.getItem(REFRESH_TOKEN_STORAGE),
            Id: localStorage.getItem(CONTACT_ID_STORAGE)
        }, undefined, undefined, {});

         if (resp.status >= 200 && resp.status < 300 ) {
            localStorage.setItem(REFRESH_TOKEN_STORAGE, resp.data.refresh_token);
            localStorage.setItem(ACCESS_TOKEN_STORAGE, resp.data.access_token);

             yield put({
                 type: ACTIONS.USER.UPDATE_TOKEN,
                 payload: {
                     accessToken: resp.data.access_token,
                 }
             })

             
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
             axios.defaults.headers.common['Authorization'] = `Bearer ${resp.data.access_token}`;

         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* logout(action: any): any {
    try {
        yield put({
            type: ACTIONS.USER.LOGOUT,
        });

        localStorage.clear();
        sessionStorage.clear();

        window.location.reload();

         // Delete Authorisation Header
        //  delete axios.defaults.headers.common["Authorization"];

        // action && action.callbackSuccess && action.callbackSuccess({});
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* post(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_BASE_URL+'/ExecuteRequest', data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.isSuccess  && resp.data.outputParameters) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getprivacyPolicy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.USER.PRIVACY_POLICY, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registerStepOne(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER.STEP1, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
             sessionStorage.setItem("ProfileId", JSON.stringify(resp.data.ProfileId));
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registerStepTwo(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER.STEP2, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registerStepThree(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER.STEP3, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registerStepFour(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER.STEP4, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* retrieveProfile(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.USER.RETRIEVE_PROFILE, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* retrievePassports(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.USER.RETRIEVE_PASSPORTS, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateProfile(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.UPDATE_PROFILE, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}