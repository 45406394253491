import React from 'react';

export const TravelIcon = (props: Record<string, unknown>) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_1641_4750)">
        <path
          d="M17.1401 5.71436H4.28292C2.70497 5.71436 1.42578 6.99354 1.42578 8.5715V16.4286C1.42578 18.0066 2.70497 19.2858 4.28292 19.2858H17.1401C18.718 19.2858 19.9972 18.0066 19.9972 16.4286V8.5715C19.9972 6.99354 18.718 5.71436 17.1401 5.71436Z"
          stroke="#8181A5"
          stroke-width="1.71429"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.42578 19.2858V5.71436"
          stroke="#8181A5"
          stroke-width="1.71429"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.9971 19.2858V5.71436"
          stroke="#8181A5"
          stroke-width="1.71429"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.14014 5.71425C7.14014 4.76705 7.51641 3.85864 8.18618 3.18887C8.85596 2.5191 9.76436 2.14282 10.7116 2.14282C11.6588 2.14282 12.5672 2.5191 13.2369 3.18887C13.9067 3.85864 14.283 4.76705 14.283 5.71425"
          stroke="#8181A5"
          stroke-width="1.71429"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1641_4750">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.711426)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
