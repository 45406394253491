import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
  BlankReq,
  LoginReq,
  retrieveProfileType,
} from '../../interfaces/ApiReqRes'


export function useUserApi() {

  const callApi = useApiCall()

  const login = (data: LoginReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  }

  const refreshToken = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REFRESH_TOKEN, {}, onSuccess, onError);
  }

  const logout = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGOUT, data, onSuccess, onError);
  }

  const getprivacyPolicy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.PRIVACY_POLICY, data, onSuccess, onError);
  }

  const getCitizenPassport = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RETRIEVE_PASSPORTS, data, onSuccess, onError);
  }

  const registerStepOne = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER.STEP1, data, onSuccess, onError);
  }

  const registerStepTwo = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER.STEP2, data, onSuccess, onError);
  }

  const registerStepThree = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER.STEP3, data, onSuccess, onError);
  }

  const registerStepFour = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER.STEP4, data, onSuccess, onError);
  }

  const retrieveProfile = (data: retrieveProfileType, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RETRIEVE_PROFILE, data, onSuccess, onError);
  }

  const forgotPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError);
  }

  const resetPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError);
  }

  const updateProfile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_PROFILE, data, onSuccess, onError);
  }

  return {
    login: login,
    refreshToken,
    logout: logout,
    getprivacyPolicy: getprivacyPolicy,
    registerStepOne: registerStepOne,
    registerStepTwo: registerStepTwo,
    registerStepThree: registerStepThree,
    registerStepFour: registerStepFour,
    retrieveProfile: retrieveProfile,
    updateProfile: updateProfile,
    getCitizenPassport,
    forgotPassword,
    resetPassword
  }
}