import React, { useState, useEffect } from "react";
import '../styles/components/travels/travelCard.scss';
import { Accordion, Carousel, Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import Header from "../components/header";
import parse from 'html-react-parser';
import LeftPanel from "../components/leftPanel";
import { IMAGE_ASSETS } from "../assets";

export default function CountryInfo() {

    const { id } = useParams()
    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader()

    const [countryId, setCountryId] = useState<string>("")
    const [details, setDetails] = useState<any>(null)

    const getCountryInfo = () => {
        showLoader()
        commonApi.getCountryInfo({ country: countryId },
            (message: string, resp: any) => {
                console.log({ resp })
                setDetails(resp)
                hideLoader()
            }, (message: any) => {
                console.log({ message })
                hideLoader()
            })
    }

    useEffect(() => {
        if (id) {
            setCountryId(id)
        }
    }, [id])

    useEffect(() => {
        if (countryId != "") {
            getCountryInfo()
        }
    }, [countryId])

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="wrapper-left-right-panel">
                    <LeftPanel img={IMAGE_ASSETS.travelSuitcases} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."} />
                    {details ?
                        <div className="dashboard">
                            <Container className="dashboard-container">
                                <div className="main-div travelcity-listinfo">
                                    <h2>{details?.CountryInfo?.Name}</h2>
                                    <div className="city-country-scroll-view">
                                        <div className="cityinfo_image">
                                            {details?.Attachments && details?.Attachments.length > 0 ?
                                                <Carousel className="details-carousal">
                                                    {details?.Attachments.map((item: any, i: number) => (
                                                        <Carousel.Item key={i}>
                                                            <img
                                                                className="d-block w-100"
                                                                src={item}
                                                                alt="First slide"
                                                            />
                                                        </Carousel.Item>
                                                    ))}
                                                </Carousel>
                                                : null
                                            }
                                        </div>
                                        <Accordion defaultActiveKey={['0', '1', '2', '3']}>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header><span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg></span>General Information</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="accordian_listtxt">
                                                        <ul>
                                                            <li><b>Country name: </b><span>{details?.CountryInfo?.Name}</span></li>
                                                            <li><b>Languages: </b><span>{details?.CountryInfo?.Languages}</span></li>
                                                            <li><b>Ambassador: </b><span>{details?.CountryInfo?.Ambassador}</span></li>
                                                            <li><b>DialCode: </b><span>{details?.CountryInfo?.DialCode}</span></li>
                                                        </ul>
                                                    </div>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header><span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg></span>Crime News</Accordion.Header>
                                                <Accordion.Body>
                                                    {details && details?.CountryInfo?.CrimeNews ? parse(details?.CountryInfo?.CrimeNews) : null}
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header><span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg></span>Cultural Information</Accordion.Header>
                                                <Accordion.Body>
                                                    {details && details?.CountryInfo?.CultureInfo ? parse(details?.CountryInfo?.CultureInfo) : null}
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header><span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg></span>Do's And Don't</Accordion.Header>
                                                <Accordion.Body>
                                                    {details && details?.CountryInfo?.DosAndDons ? parse(details?.CountryInfo?.DosAndDons) : null}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    <Link className="btn btn-success mt-2" to="/country-directory">Back</Link>
                                </div>
                            </Container>
                        </div>
                        : null
                    }
                </div>
            </Container>
        </React.Fragment>

    )
}