import React, { useEffect, useState } from 'react'
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import Header from "../components/header";
import LeftPanel from "../components/leftPanel";
import * as CountryList from 'country-list-with-dial-code-and-flag';
import { useNavigate } from 'react-router-dom';
import { useCasesApi } from '../_common/hooks/api/casesApiHook';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';
import { useForm, Controller } from 'react-hook-form';
import ConsentCreateCaseModal from "../components/modals/consentCreateCase.modal";

import toast from 'react-hot-toast';
import { FaPlusCircle, FaTimes } from 'react-icons/fa';
import travelSuitecase from '../assets/privacy-policy/suitcases.png';
import Select from '../components/blocks/Select';
import { InputField } from '../components/InputField';
import ToggleSwitch from '../components/blocks/toggleSwitch';
import { css } from '@emotion/css';
import { FileInput } from '../components/blocks/FileInput';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { THIRTEEN_DIGITS_ONLY_PATTERN, OTHER_RELATIONSHIP_CODE } from '../_common/constants';
import { ApiSearchBox } from '../components/ApiSearchbox';
import { API_URL } from '../_config';

const CreateCase = () => {

  const navigate = useNavigate()
  const caseApi = useCasesApi()
  const commonApi = useCommonApi()
  const { showLoader, hideLoader } = useAppLoader()
  const user: any = useUserSelector()


  const { register, handleSubmit, control, formState: { errors }, watch, reset } = useForm({ mode: "onChange" });
  const documents = watch('documents');
  const country = watch('CountryId');

  const [files, setFiles] = useState<any>([]);
  const [casesTypes, setCasesTypes] = useState<any>(null)
  const [countries, setCountries] = useState<any>(null)
  const [cities, setCities] = useState<any>(null)
  const [dialCode, setDialCode] = useState<any>(null)
  const [aloneToggle, setAloneToggle] = useState<boolean>(false)
  const [locationToggle, setLocationToggle] = useState<boolean>(false)
  const [affectedToggle, setAffectedToggle] = useState<boolean>(false)
  const [relationship, setRelationship] = useState<any>(null)
  const [caseMesssage, setCaseMessage] = useState<boolean>(false)
  const [show, setShow] = useState(true);
  const [passports, setPassports] =  useState([])

  const userApi = useUserApi()

  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  useEffect(() => {
    let _files: any = [...files];
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        let file: any = documents[i];
        checkIsSelected(file.name).then(() => {
          if (file.size <= 2 * 1024 * 1024) {
            toBase64(file).then((b64: any) => {
              _files.push({
                "FileName": file.name,
                // "FileExtention": file.name.split('.').pop(),
                // "DocumentTypeId": docType,
                "FileContent": b64.split(',')[1]
              });
              // if (_files.length == documents?.length) {
              console.log('files=>', _files);
              setFiles(_files)
              // }
            })
          } else {
            toast.error("File size should be less 2MB")
          }
        }).catch(() => { console.log('already selected') })
      }
    } else {
      console.log('files=>', _files);
      setFiles(_files)
    }
    // reset({
    //   documents: null
    // })
  }, [documents])

  const checkIsSelected = (name: string) => {
    // console.log('checking.................', files)
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject()
        }
      });
      resolve()
    })
  }

  const getCasesTypes = () => {
    caseApi.getCasesTypes({},
      (message: string, resp: any) => {
        // console.log({ resp })
        if (resp.length > 0) {
          setCasesTypes(resp.map((data: any) => {
            data.label = data.xql_name;
            data.value = data.xql_caseclassificationid;
            return data;
          }))
        }
      }, (message: string) => {
        console.log(message);
      })
  }

  const getCountries = () => {
    commonApi.getCountries({},
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setCountries(resp.map((data: any) => {
            data.label = data.xql_name;
            data.value = data.xql_countryid;
            return data;
          }))
        }
      }, (message: string) => {
        console.log(message);
      })
  }

  const getPassports = () => {
    userApi.getCitizenPassport({contact: user.contactid},
      (message: string, resp: any) => {
        if (resp.IsSuccess) {
          setPassports(resp.Passports.map((data: any) => {
            data.value = data.PassportId
            data.label = data.Passport
            return data
          }))
        }
      }, (message: string) => {
        console.log(message);
      }
    )
  }

  const getCities = (id: any = country?.value) => {
    return new Promise((resolve: any, reject: any) => {
      commonApi.getCities({
        CountryId: id
      }, (message: string, resp: any) => {
        if (resp.length > 0) {
          setCities(resp.map((data: any) => {
            data.label = data.xql_name;
            data.value = data.xql_cityid;
            return data;
          }))
        }
        setTimeout(() => {
          resolve()
        }, 300)
      }, (message: string) => {
        // console.log(message);
        reject()
      });
    })
  }

  const retrieveRelationShip = () => {
    commonApi.retrieveRelationShip({ "DropdownName": "xql_relationshiotype" },
      (message: string, resp: any) => {
        let options: any = [];
        if (resp.Options && resp.Options.length > 0) {
          resp.Options.map((item: any) => {
            item.value = item.Value
            item.label = item.Label.UserLocalizedLabel.Label
            options.push(item)
          })
          setRelationship(options)
        }
      }, (message: string) => {
        console.log(message);
      })
  }

  const getDialCode = (data: any) => {
    if (data.length > 0) {
      setDialCode(data.map((item: any) => {
        item.label = `(${item.dial_code}) ${item.name}`;
        item.value = item.dial_code;
        return item;
      }))
    }
  }

  const dialCodeOptionUI = (props: any) => {
    const { innerProps, innerRef, data } = props;
    return (
      <div ref={innerRef} {...innerProps} className="dial-code-option">
        {data.flag}
        <span>{data.dial_code}</span>
      </div>
    );
  }

  const onSubmit = (data: any) => {
    let params = {
      "EntityName": "Case",
      "ContactId": user?.contactid,
      "CaseTypeId": data.CaseTypeId,
      "Description": data.Description,
      "CountryId": data.CountryId,
      "PassportId": data.PassportId,
      "CityId": data.CityId,
      "ShareLocation": locationToggle,
      "AreYouAlone": aloneToggle,
      "AreYouTheAffectedParty": affectedToggle,
      "IDNumber": "",
    }
  
    showLoader();
    caseApi.createCase(params,
      (message: string, resp: any) => {
        hideLoader()
        if (affectedToggle) {
          submitStepTwo(data, resp.CaseId)
        }
        if (resp.IsAutoCase) {
          createCaseNotification(resp)
        }
        docUpload(resp.CaseId)
        // toast.success(message)
        reset({
          CaseTypeId: null,
          CountryId: null,
          CityId: null,
          Description: null,
          documents: null,
          IDNumber: null,
          FirstName: null,
          LastName: null,
          Email: null,
          DialCode: null,
          MobilePhone: null
        });
        setFiles([]);
        navigate(`/cases`)
      }, (message: string) => {
        console.log({ message })
        hideLoader()
        toast.error(message)
        // reset({
        //   CaseTypeId: null,
        //   CountryId: null,
        //   CityId: null,
        //   Description: null,
        //   documents: null,
        //   IDNumber: null,
        //   FirstName: null,
        //   LastName: null,
        //   Email: null,
        //   DialCode: null,
        //   MobilePhone: null
        // });
        // setFiles([]);
      })
  }

  const relationshipWatch = watch('RelationshipType')

  const submitStepTwo = (data: any, caseId: any) => {
    let params = {
      "EntityName": "AffectedParty",
      "CaseId": caseId,
      "AreYouTheAffectedParty": affectedToggle,
      "IDNumber": data.IDNumber,
      "RelationshipType": Number(data.RelationshipType),
      "FirstName": data.FirstName,
      "LastName": data.LastName,
      "Email": data.Email,
      "DialCode": data.DialCode.value,
      "MobilePhone": data.MobilePhone,
      "OtherRelationship": data.OtherRelationship || ''
    }

    caseApi.createCaseStepTwo(params,
      (message: string, resp: any) => {
        reset({
          CaseTypeId: null,
          CountryId: null,
          CityId: null,
          Description: null,
          documents: null,
          IDNumber: null,
          FirstName: null,
          LastName: null,
          Email: null,
          DialCode: null,
          MobilePhone: null,
          RelationshipType: null,
          OtherRelationship: null
        });
      }, (message: string) => {
        // reset({
        //   CaseTypeId: null,
        //   CountryId: null,
        //   CityId: null,
        //   Description: null,
        //   documents: null,
        //   IDNumber: null,
        //   FirstName: null,
        //   LastName: null,
        //   Email: null,
        //   DialCode: null,
        //   MobilePhone: null
        // });
        console.log(message)
      })
  }

  const docUpload = (caseId: any) => {
    let params = {
      "CaseId": caseId,
      "Images": files
    }
    if (files.length > 0) {
      caseApi.createCaseDocUpload(params,
        (message: string, resp: any) => { },
        (message: string) => { })
    }
  }

  const deleteLocal = (file: any) => {
    let _files: any = [];
    files.map((_file: any) => {
      if (_file.FileName != file.FileName) {
        _files.push(_file)
      }
    });

    setFiles(_files)
  }

  const createCaseNotification = (resp: any) => {
    toast(
      (t) => (
        <span>
          <div className='modal-tag-notification' style={{ background: 'black', fontSize: "12px", borderRadius: "0 5px 5px 0", color: "white", display: "inline-block", marginBottom: "10px", padding: "3px 6px" }}>{resp?.CaseStatus}</div>
          <br />
          <b >Your case with case number #{resp?.CaseNumber} has be automatically closed.</b>
          <br />
          <br />
          <p>Consular Service are of an advisory and <b>non-financial</b> nature and are provided free of charge, unless otherwise specified.</p>
          <br />
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-success w-100"
              onClick={() => { toast.dismiss(t.id); navigate(`/cases`) }}
            >
              Go back
            </button>
          </div>
        </span>
      ),
      { duration: 20000, className: 'withdraw-toast', position: "bottom-center" }
    );
  }

  useEffect(() => {
    getCasesTypes()
    getCountries()
    retrieveRelationShip()
    getPassports()
    getDialCode(CountryList.getList())
  }, [])

  useEffect(() => {
    if (country) {
      getCities(country)
    }
  }, [country])

  return (
    <React.Fragment>
      <Header active={'cases'} />
      <Container fluid>
        <div className="wrapper-left-right-panel">
          <LeftPanel
            img={travelSuitecase}
            text={
              'If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly.'
            }
          />
          <div className="create-case-form w-100">
            <h2>Create case</h2>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Row
                className="mt-2
              "
              >
                <Col sm={6}>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h3>Create case for a third party</h3>
                    <ToggleSwitch
                      setCondition={setAffectedToggle}
                      condition={affectedToggle}
                    />
                  </div>
                </Col>
              </Row>

              {affectedToggle ? (
                <React.Fragment>
                  <Row>
                    <Col sm={6}>
                       <InputField id='IDNumber' required={affectedToggle} label='SA ID Number' register={register} errors={errors} registerParams={{
                              pattern: {
                                  value: THIRTEEN_DIGITS_ONLY_PATTERN,
                                  message: 'Enter a valid 13-digit national ID number',
                              },
                           }} />
                    </Col>
                    <Col sm={6}>
                      <fieldset
                        className={
                          'input' + (errors?.FirstName ? ' invalid' : '')
                        }
                      >
                        <legend>First Name</legend>
                        <input
                          {...register('FirstName', {
                            required: affectedToggle,
                            maxLength: 80,
                          })}
                        />
                      </fieldset>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <fieldset
                        className={
                          'input' + (errors?.LastName ? ' invalid' : '')
                        }
                      >
                        <legend>Last Name</legend>
                        <input
                          {...register('LastName', {
                            required: affectedToggle,
                            maxLength: 80,
                          })}
                        />
                      </fieldset>
                    </Col>
                    <Col sm={6}>
                      <fieldset
                        className={
                          'input' + (errors?.Email ? ' invalid' : '')
                        }
                      >
                        <legend>Email</legend>
                        <input
                          {...register('Email', {
                            // required: affectedToggle,
                            maxLength: 80,
                          })}
                        />
                      </fieldset>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <fieldset
                          className={
                            'input' +
                            (errors?.RelationshipType ? ' invalid' : '')
                          }
                        >
                          <legend>Relationship</legend>
                          <Controller
                            name={'RelationshipType'}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => {
                              return (
                                <Select
                                  className={'select-box'}
                                  options={relationship ? relationship : []}
                                  placeholder={''}
                                  onChange={onChange}
                                  value={value}
                                  defaultValue={value}
                                />
                              );
                            }}
                          />
                      </fieldset>
                      {
                        relationshipWatch === OTHER_RELATIONSHIP_CODE && 
                        <InputField id='OtherRelationship' required={true} label='How are you related?' register={register} errors={errors} />
                      }
                    </Col>
                    <Col sm={2}>
                      <fieldset
                        className={
                          'input' + (errors?.DialCode ? ' invalid' : '')
                        }
                      >
                        <legend>Code</legend>
                        <Controller
                          name={'DialCode'}
                          control={control}
                          rules={{ required: affectedToggle }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (
                              <Select
                                components={{ Option: dialCodeOptionUI }}
                                className={'select-box'}
                                options={dialCode ? dialCode : []}
                                placeholder={''}
                                onChange={onChange}
                                value={value}
                                defaultValue={value}
                              />
                            );
                          }}
                        />
                      </fieldset>
                    </Col>
                    <Col sm={4}>
                      <fieldset
                        className={
                          'input' +
                          (errors?.MobilePhone ? ' invalid' : '')
                        }
                      >
                        <legend>Phone Number</legend>
                        <input
                          type="text"
                          className=""
                          {...register('MobilePhone', {
                            required: affectedToggle,
                          })}
                        ></input>
                      </fieldset>
                    </Col>
                  </Row>
                </React.Fragment>
              ) : null}

              <Row>
                <Col xs={12} sm={6}>
                  <fieldset
                    className={
                      'input' + (errors?.CaseTypeId ? ' invalid' : '')
                    }
                  >
                    <legend>Case Type</legend>
                    <Controller
                      name={'CaseTypeId'}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Select
                            className={'select-box'}
                            options={casesTypes ? casesTypes : []}
                            placeholder={''}
                            onChange={onChange}
                            value={value}
                            defaultValue={value}
                          />
                        );
                      }}
                    />
                  </fieldset>
                </Col>
                <Col xs={12} sm={6}>
                  <fieldset
                    className={
                      'input' + (errors?.CountryId ? ' invalid' : '')
                    }
                  >
                    <legend>Country</legend>
                    <Controller
                      name={'CountryId'}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Select
                            className={'select-box'}
                            options={countries ? countries : []}
                            placeholder={''}
                            onChange={onChange}
                            value={value}
                            defaultValue={value}
                          />
                        );
                      }}
                    />
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                    {
                      affectedToggle ?
                      <InputField id='PassportId' required={false} label='Passport' register={register} errors={errors} />
                      :
                      <fieldset className={"input" + (errors?.PassportId ? ' invalid' : '')}>
                        <legend>Passport</legend>
                        <Controller
                            name={"PassportId"}
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { value, onChange, onBlur } }) => {
                                return (<Select
                                    className={"select-box"}
                                    options={passports ? passports : []}
                                    placeholder={""}
                                    onChange={onChange}
                                    value={value}
                                    defaultValue={value}
                                />)
                            }}
                        />
                    </fieldset>
                    }
                </Col>
                <Col xs={12} sm={6}>
                  <fieldset
                    className={
                      'input' + (errors?.CityId ? ' invalid' : '')
                    }
                  >
                    <legend>City</legend>
                    <Controller
                                    name={'CityId'}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <ApiSearchBox
                                                {...field}
                                                endpointUrl={API_URL.COMMON.GET_CITIES}
                                                endpointMethod="POST"
                                                body={{
                                                    CountryId: country,
                                                    Name: '{{value}}',
                                                }}
                                                parserFunction={(data) => {
                                                    return (data as Record<string, string>[]).map(
                                                        (el) => {
                                                            return {
                                                                label: el.xql_name,
                                                                value: el.xql_cityid,
                                                            };
                                                        }
                                                    );
                                                }}
                                            />
                                        );
                                    }}
                                />
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <fieldset
                    className={
                      'input' + (errors?.Description ? ' invalid' : '')
                    }
                  >
                    <legend>Description</legend>
                    <textarea
                      rows={4}
                      {...register('Description', {
                        required: true, 
                      })}
                    />
                  </fieldset>
                </Col>
                <Col xs={12} sm={6}>
                  <FileInput files={files} onDeleteFile={deleteLocal}
                    {...register('documents', { required: false })}
                    />
                  {/* <div
                    className={css({
                      border: '2px solid #356b1a',
                      borderRadius: 8,
                      position: 'relative',
                      top: 8,
                    })}
                  >

                    <ListGroup>
                      {files.map((file: any, i: any) => {
                        return (
                          <ListGroup.Item>
                            <div className={css({ display: 'flex' })}>
                              <FaTimes
                                style={{ color: 'red' }}
                                className="me-2"
                                onClick={() => {
                                  deleteLocal(file);
                                }}
                              />
                              <small
                                className={css({
                                  width: '100%',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                })}
                              >
                                {file.FileName}
                              </small>
                            </div>
                          </ListGroup.Item>
                        );
                      })}
                      <ListGroup.Item action>
                        <label>
                          <div
                            className={css({
                              display: 'inline-flex',
                              whiteSpace: 'nowrap',
                              alignItems: 'center',
                            })}
                          >
                            <FaPlusCircle />&nbsp;<strong>Add Attachment...</strong>
                          </div>
                          <input
                            className={css({
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              opacity: 0,
                            })}
                            type="file"
                      
                          />
                        </label>
                      </ListGroup.Item>
                    </ListGroup>
                    <legend className={css({
                     background: 'white',
                     fontSize: '0.75rem',
                     color: '#356b1a',
                     marginLeft: 10,
                     marginRight: 10,
                     paddingLeft: 10,
                     paddingRight: 10,
                     display: 'inline-block',
                     width: 'fit-content',
                     position: 'absolute',
                     zIndex: 1300,
                     top: -8,
                    })} >Attachments</legend>
                  </div> */}
                </Col>
              </Row>
              <Row></Row>

              <Row className="my-4">
                <Col sm={6}>
                  <button type="submit" className="btn btn-success w-100">
                    Create case
                  </button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </Container>
      <ConsentCreateCaseModal show={show} onHide={()=>setShow(false)}/> 
    </React.Fragment>
  );
}

export default CreateCase