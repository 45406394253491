import React from 'react';

export const ActivityIcon = (props: Record<string, unknown>) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.4"
        d="M6.17822 13.5035L9.17136 9.61329L12.5855 12.2952L15.5146 8.51489"
        stroke="#8181A5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="18.929"
        cy="2.9222"
        r="1.9222"
        stroke="#8181A5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8579 1.84204H6.59014C3.5787 1.84204 1.71143 3.97476 1.71143 6.98621V15.0686C1.71143 18.08 3.54209 20.2036 6.59014 20.2036H15.1943C18.2057 20.2036 20.073 18.08 20.073 15.0686V8.02968"
        stroke="#8181A5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
