import { Form } from 'react-bootstrap';
import StateManagedSelect from 'react-select/dist/declarations/src/stateManager';

const Select: StateManagedSelect = ({
  options = [],
  onChange,
  value,
  defaultValue,
  placeholder,
  isDisabled,
  ...restOfParams
}) => {
  return (
    <Form.Select
    key={defaultValue || 1}
      placeholder={(placeholder as any) || ''}
      disabled={isDisabled}
      onChange={onChange as any}
      value={value as any}
      defaultValue={defaultValue as any}
      style={{fontSize: '0.8rem', paddingLeft: 8}}
      {...(restOfParams as any)}
    >
          <option value=""></option>
      {options.map((option) => {
        return (
          <option value={(option as any).value}>{(option as any).label}</option>
        );
      })}
    </Form.Select>
  );
};

export default Select;
