import axios from 'axios';
import { ACCESS_TOKEN_STORAGE, URLS} from '../../../_config';

var apiKey = 'xINsj.eIdZ9F9E3y5PvX9rJ7NmEXbE0UIkYHlHxOOfdRsLkON';
var ocpApimSubscriptionKey = '31e189b9fdda438ea31372fc8fa26b72';


export class CallApi {
  static GET(url, params, isAuth = true, isAdaptive) {
    return new Promise((resolve, reject) => {
      let headers = { 'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey };
      if (isAuth || isAdaptive) {
        const token = localStorage.getItem(ACCESS_TOKEN_STORAGE);
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }
      }
      return axios({
        method: 'GET',
        url,
        headers,
        params,
      }).then(success => {
        return resolve(success);
      }).catch(error => {
        if (error.response && error.response.status === 401 && window.location.pathname != URLS.LOGIN) {
          localStorage.removeItem(ACCESS_TOKEN_STORAGE);
          // window.location.href = URLS.LOGIN;
        }
        return reject(error.response);
      });
    });
  }

  static POST(url, data, isAuth = true, isAdaptive,customHeaders) {
    return new Promise((resolve, reject) => {
      const baseHeaders = { 'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey };

      const headers = {...baseHeaders}

      if (isAuth || isAdaptive) {
        const token = localStorage.getItem(ACCESS_TOKEN_STORAGE);
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }
      }
      console.log({customHeaders})

      console.log({
        method: 'POST',
        url,
        headers:  customHeaders ? {...baseHeaders, ...customHeaders} : headers,
        data,
      })


      return axios({
        method: 'POST',
        url,
        headers:  customHeaders ? {...baseHeaders, ...customHeaders} : headers,
        data,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          if (error.response && error.response.status === 401 && window.location.pathname != URLS.LOGIN) {
            localStorage.removeItem(ACCESS_TOKEN_STORAGE);
            // window.location.href = URLS.LOGIN;
          }
          return reject(error.response);
        });
    });
  }

  static PUT(url, data, isAuth = true, isAdaptive) {
    return new Promise((resolve, reject) => {
      let headers = { 'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey };
      if (isAuth || isAdaptive) {
        const token = localStorage.getItem(ACCESS_TOKEN_STORAGE);
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }
      }
      return axios({
        method: 'PUT',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          if (error.response && error.response.status === 401 && window.location.pathname != URLS.LOGIN) {
            localStorage.removeItem(ACCESS_TOKEN_STORAGE);
            // window.location.href = URLS.LOGIN;
          }
          return reject(error.response);
        });
    });
  }

  static DELETE(url, data, isAuth = true, isAdaptive) {
    return new Promise((resolve, reject) => {
      let headers = { 'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey };
      if (isAuth || isAdaptive) {
        const token = localStorage.getItem(ACCESS_TOKEN_STORAGE);
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }
      }
      return axios({
        method: 'DELETE',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          if (error.response && error.response.status === 401 && window.location.pathname != URLS.LOGIN) {
            localStorage.removeItem(ACCESS_TOKEN_STORAGE);
            // window.location.href = URLS.LOGIN;
          }
          return reject(error.response);
        });
    });
  }
}
