import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../styles/dashboard.scss';
import Header from '../components/header';
import { css } from "@emotion/css";

export default function Dashboard() {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Header active={"home"}/>
            <div className="dashboard">
                <Container className="dashboard-container">
                    <div className="main-div">
                        <Row>
                            <Col sm={12} className="dashboard-img-block">
                                <img src="/assets/img/dashboard-banner.jpg" className="dashboard-img" alt="" />
                            </Col>
                        </Row>

                        <Row>
                          <Col sm={3} className="mb-3">
                            <Card onClick={() => navigate(`/advisory`)} style={{ cursor: "pointer" }}>
                                    <Card.Body>
                                        <Card.Title>
                                            <img src="/assets/img/bell-icon.svg" alt="" />
                                        </Card.Title>
                                        <Card.Text>
                                            Alerts
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={3} className="mb-3">
                                <Card onClick={() => navigate(`/create-case`)} style={{ cursor: "pointer" }} >
                                    <Card.Body>
                                        <Card.Title>
                                            <img src="/assets/img/cases-icon.svg" alt="" />
                                        </Card.Title>
                                        <Card.Text>
                                            Do you need help?
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                       
                            <Col sm={3} className="mb-3">
                                <Card onClick={() => navigate(`/profile-settings`)} style={{ cursor: "pointer" }}>
                                    <Card.Body>
                                        <Card.Title>
                                            <img src="/assets/img/profile-icon.svg" alt="" />
                                        </Card.Title>
                                        <Card.Text>
                                            Profile
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={3} className="mb-3">
                                <Card onClick={() => navigate(`/travels`)} style={{ cursor: "pointer" }} >
                                    <Card.Body>
                                        <Card.Title>
                                            <img src="/assets/img/travels-icon.svg" alt="" />
                                        </Card.Title>
                                        <Card.Text>
                                            My Travels
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={3} className="mb-3">
                                <Card>
                                    <a  href={'https://www.dirco.gov.za/websites-of-sa-mission/'}   
							  className={css({
                                textDecoration: 'none'
                              })} target="_blank" rel="noreferer noopener noreferrer">
                                    <Card.Body>
                                        <Card.Title>
                                            <img src="/assets/img/diplomatic-icon.svg" alt="" />
                                        </Card.Title>
                                        <Card.Text>
                                            SA Missions
                                        </Card.Text>
                                    </Card.Body></a>
                                </Card>
                            </Col>
                            <Col sm={3} className="mb-3">
                                <Card onClick={() => navigate(`/cases`)} style={{ cursor: "pointer" }} >
                                    <Card.Body>
                                        <Card.Title>
                                            <img src="/assets/img/cases-icon.svg" alt="" />
                                        </Card.Title>
                                        <Card.Text>
                                            Case History
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}