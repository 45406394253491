import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { css } from '@emotion/css'
import { string } from "yup";

interface props {
    show: boolean;
    onHide?: () => void;
}

const ConsentCreateCaseModal = ({show, onHide }: props) => {
    const[agree, setAgree] = useState(true);

    const navigate = useNavigate();

    return (
        <>
            <Modal className='opencase-modal'
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            consent-modal-body
            >
                <Modal.Body>
                <h4>DIRCO provides the following services: </h4>
                <p>
                   
                    <ul>
                        <li>Advising and communicating South Africa’s foreign policy imperatives and positions </li>
                        <li>Promoting and advancing national interests and our foreign policy priorities within and engagements with the international community </li>
                        <li>Coordinating and enhancing participation by all spheres of government in the implementation of South Africa’s foreign policy</li>
                        <li>Rendering consular and legalisation services </li>
                        <li>All the services are supported by effective and efficient administrative governance.</li>
                    </ul>
                </p>

                <div className={css({display: 'flex'})}>
                    <div>
                        <div className="form-check form-switch" >
                        <input className="form-check-input modal-check-input" type="checkbox" role="switch" checked={agree} id="modalSwitchDefault" onChange={(e) => {setAgree(!agree)} }/>
                        <label className="form-check-label" htmlFor="modalSwitchDefault"></label>
                        </div>
                    </div>

                    <div className={css({ flex: 1, gap: 6, display: "inline-flex", alignItems: "center"})}>
                    {agree && <span>&nbsp;I agree</span>}
                    {!agree && <span>&nbsp;</span>}
                    </div>       
                </div>

                <div className={css({display: 'flex',   marginTop: 16, gap: 8})} >
                   <button type="button"  
               
                   className="btn btn-secondary  btn-modal w-50" 
                   onClick={() => navigate("/")}>Close</button>
                                      <button type="button"  
                   disabled={!agree} 
                   className="btn btn-primary btn-modal w-50" 
                   onClick={() => onHide?.()}>Create case</button>
               </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConsentCreateCaseModal;